<!-- register component -->
<body class="dark:bg-white">
<div class="overflow-hidden">
  <div class="flex flex-col min-h-screen bg-white-100">
    <app-header></app-header>
    <div class="flex-grow flex justify-center items-center">
      <div class="relative py-3 sm:mx-auto sm:max-w-xl">
        <div
          class="absolute inset-0 -skew-y-6 transform bg-gradient-to-r bg-[#CCD56A] shadow-lg sm:-rotate-6 sm:skew-y-0 sm:rounded-3xl"
        ></div>
        <div
          class="relative bg-white px-4 py-6 shadow-lg sm:rounded-3xl sm:p-16"
        >
          <div class="mx-auto max-w-md">
            <div>
              <h1 class="text-4xl font-times dark:text-black">Register</h1>
            </div>
            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" novalidate>

              <!-- <div class="grid grid-cols-2 gap-6 py-8 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7"> -->
              <div
                class="gap-6 py-8 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7"
              >
                <!-- Left Column -->
                <div class="space-y-4">
                  <!-- First Name -->
                  <!-- <div class="relative">
                      <input
                        name="first_name"
                        [(ngModel)]="formData.first_name"
                        type="text"
                        class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none font-times"
                        placeholder="First Name"
                        id="first_name"
                        required
                      />
                      <label
                        for="first_name"
                        class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                      >
                        First Name
                      </label>
                    </div> -->

                  <!-- Last Name -->
                  <!-- <div class="relative">
                      <input
                        name="last_name"
                        [(ngModel)]="formData.last_name"
                        type="text"
                        class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none font-times"
                        placeholder="Last Name"
                        id="last_name"
                        required
                      />
                      <label
                        for="last_name"
                        class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                      >
                        Last Name
                      </label>
                    </div> -->

                  <!-- Email -->
                  <div class="relative">
                    <input
                    formControlName="email"
                      type="email"
                      class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-times"
                      placeholder="Email Address"
                      id="email"
                      required
                      email
                    />
                    <label
                      for="email"
                      class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                    >
                      Email Address
                    </label>
                    <div *ngIf="registerForm.get('email')?.invalid && registerForm.get('email')?.touched">
                      <small *ngIf="registerForm.get('email')?.errors?.['required']" class="text-red-600">
                        Email is required.
                      </small>
                      <small *ngIf="registerForm.get('email')?.errors?.['email']" class="text-red-600">
                        Enter a valid email address.
                      </small>
                    </div>
                  </div>

                  <!-- Mobile Number -->
                  <div class="relative">
                    <input
                    formControlName="mobile"
                      name="mobile"
                      type="tel"
                      class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-times"
                      placeholder="Mobile Number"
                      id="mobile"
                      required
                    />
                    <label
                      for="mobile"
                      class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                    >
                      Mobile Number
                    </label>
                    <div *ngIf="registerForm.get('mobile')?.invalid && registerForm.get('mobile')?.touched">
                      <small *ngIf="registerForm.get('mobile')?.errors?.['required']" class="text-red-600">
                        Mobile number is required.
                      </small>
                      <small *ngIf="registerForm.get('mobile')?.errors?.['pattern']" class="text-red-600">
                        Enter a valid 10-digit number.
                      </small>
                    </div>
                  </div>

                  <!-- Password -->
                  <div class="relative">
                    <input
                    formControlName="password"
                    type="password"
                    class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-times"
                    placeholder="Password"
                    (blur)="validatePassword()"
                    [ngClass]="{ 'is-invalid': password?.touched && password?.invalid }"
                  />
                    <label
                      for="password"
                      class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                    >
                      Password
                    </label>
                    <div *ngIf="password?.touched && password?.invalid">
                      <small *ngIf="password?.errors?.['required']" class="text-red-600">
                        Password is required.
                      </small>
                      <small *ngIf="password?.errors?.['minlength']" class="text-red-600">
                        Password must be at least 8 characters long.
                      </small>
                      <small *ngIf="password?.errors?.['lowercase']" class="text-red-600">
                        Password must contain at least one lowercase letter.
                      </small>
                      <small *ngIf="password?.errors?.['uppercase']" class="text-red-600">
                        Password must contain at least one uppercase letter.
                      </small>
                      <small *ngIf="password?.errors?.['digit']" class="text-red-600">
                        Password must contain at least one digit.
                      </small>
                      <small *ngIf="password?.errors?.['special']" class="text-red-600">
                        Password must contain at least one special symbol.
                      </small>
                    </div>
                  </div>
                </div>

                <!-- Right Column -->
                <div class="space-y-4">
                  <!-- Address -->
                  <!-- <div class="relative">
                      <input
                        name="address"
                        [(ngModel)]="formData.address"
                        class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none font-times"
                        placeholder="Address"
                        id="address"
                        required
                      />
                      <label
                        for="address"
                        class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                      >
                        Address
                      </label>
                    </div> -->

                  <!-- City -->
                  <!-- <div class="relative">
                      <input
                        name="city"
                        [(ngModel)]="formData.city"
                        class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none font-times"
                        placeholder="City"
                        id="city"
                        required
                      />
                      <label
                        for="city"
                        class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                      >
                        City
                      </label>
                    </div> -->

                  <!-- State -->
                  <!-- <div class="relative">
                      <input
                        name="state"
                        [(ngModel)]="formData.state"
                        class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none font-times"
                        placeholder="State"
                        id="state"
                        required
                      />
                      <label
                        for="state"
                        class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                      >
                        State
                      </label>
                    </div> -->

                  <!-- Country -->
                  <!-- <div class="relative">
                      <input
                        name="country"
                        [(ngModel)]="formData.country"
                        class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none font-times"
                        placeholder="Country"
                        id="country"
                        required
                      />
                      <label
                        for="country"
                        class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                      >
                        Country
                      </label>
                    </div> -->

                  <!-- Pincode -->
                  <!-- <div class="relative">
                      <input
                        name="zip_code"
                        [(ngModel)]="formData.zip_code"
                        class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none font-times"
                        placeholder="Pincode"
                        id="zip_code"
                        required
                      />
                      <label
                        for="zip_code"
                        class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                      >
                        Pincode
                      </label>
                    </div> -->
                </div>
              </div>

              <!-- Submit Button Centered -->
              <div class="flex justify-center py-4">
                <button
                  type="submit"
                  [disabled]="isLoading"
                  class="bg-custom-gray hover:bg-gray-900 text-custom-text-color-button font-normal lg:font-medium py-1 lg:py-2 px-2 lg:px-4 font-times text-sm lg:text-lg tracking-widest w-fit flex items-center justify-center"
                >
                  <!-- Conditional rendering of button content -->
                  <span
                    *ngIf="isLoading"
                    class="loader border-t-2 border-white rounded-full w-4 h-4 mr-2 animate-spin"
                  ></span>
                  <span *ngIf="!isLoading"
                    ><i class="fas fa-check mr-2"></i> Submit</span
                  >
                </button>
              </div>
            </form>
            <div class="flex justify-center mt-2">
              <p class="text-lg font-times dark:text-custom-gray">
                Already have an account?
                <a routerLink="/login" class="text-blue-600 hover:underline">
                  Login here
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</body>