<div class="overflow-hidden bg-white">
  <div class="flex flex-col min-h-screen bg-white-100">
    <app-header></app-header>
    <div class="flex-grow flex justify-center items-center">
      <div class="relative py-3 sm:mx-auto sm:max-w-xl">
        <div
          class="absolute inset-0 -skew-y-6 transform bg-[#CCD56A] shadow-lg sm:-rotate-6 sm:skew-y-0 sm:rounded-3xl"
        ></div>
        <div
          class="relative bg-white px-4 py-10 shadow-lg sm:rounded-3xl sm:p-20"
        >
          <div class="mx-auto max-w-md">
            <div class="text-center">
              <div class="flex items-center justify-start mb-4">
                <button
                  (click)="goBack()"
                  class="text-black-600 dark:text-black hover:text-[#CCD56A] focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                    />
                  </svg>
                </button>
              </div>

              <h1 class="text-4xl font-times mb-4 dark:text-black">Verify OTP</h1>

              <!-- OTP Verification -->
              <div class="space-y-4">
                <div
                  class="space-y-6 py-8 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7"
                >
                  <!-- OTP Input Fields -->
                  <div
                    class="relative flex justify-center items-center space-x-2"
                  >
                    <input
                      class="mt-2 focus:border-[#CCD56A] peer h-10 w-10 text-center border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-roboto"
                      id="input1"
                      type="text"
                      maxlength="1"
                      (keyup)="onKeyUp($event, 0)"
                      [(ngModel)]="otp[0]"
                    />
                    <input
                      class="mt-2 focus:border-[#CCD56A] peer h-10 w-10 text-center border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-roboto"
                      id="input2"
                      type="text"
                      maxlength="1"
                      (keyup)="onKeyUp($event, 1)"
                      [(ngModel)]="otp[1]"
                    />
                    <input
                      class="mt-2 focus:border-[#CCD56A] peer h-10 w-10 text-center border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-roboto"
                      id="input3"
                      type="text"
                      maxlength="1"
                      (keyup)="onKeyUp($event, 2)"
                      [(ngModel)]="otp[2]"
                    />
                    <input
                      class="mt-2 focus:border-[#CCD56A] peer h-10 w-10 text-center border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-roboto"
                      id="input4"
                      type="text"
                      maxlength="1"
                      (keyup)="onKeyUp($event, 3)"
                      [(ngModel)]="otp[3]"
                    />
                    <input
                      class="mt-2 focus:border-[#CCD56A] peer h-10 w-10 text-center border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-roboto"
                      id="input5"
                      type="text"
                      maxlength="1"
                      (keyup)="onKeyUp($event, 4)"
                      [(ngModel)]="otp[4]"
                    />
                    <input
                      class="mt-2 focus:border-[#CCD56A] peer h-10 w-10 text-center border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-roboto"
                      id="input6"
                      type="text"
                      maxlength="1"
                      (keyup)="onKeyUp($event, 5)"
                      [(ngModel)]="otp[5]"
                    />

                    <label
                      class="font-roboto peer-placeholder-shown:text-gray-440 absolute -top-3.5 left-0 text-sm text-gray-600 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                      style="z-index: 10"
                    >
                      Enter 6 Digit OTP
                    </label>
                  </div>
                  <div class="relative flex justify-center items-center">
                    <button
                      type="button"
                      (click)="handleVerifyOtp()"
                      class="bg-custom-gray hover:bg-gray-900 text-custom-text-color-button font-normal lg:font-medium py-1 lg:py-2 px-2 lg:px-4 font-times text-sm lg:text-lg tracking-widest w-fit"
                    >
                      Verify OTP
                    </button>
                  </div>
                  <div class="relative flex justify-center items-center">
                    <!-- Resend OTP Section -->
                    <ng-container *ngIf="resendOtpCountdown > 0">
                      <span class="ml-5 text-gray-600">Resend OTP in {{ resendOtpCountdown }} seconds</span>
                    </ng-container>
                    <ng-container *ngIf="resendOtpCountdown === 0">
                      <a
                        (click)="handleSendOtp()"
                        class="ml-5 text-custom-gray hover:underline"
                      >
                        Resend OTP
                      </a>
                    </ng-container>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
