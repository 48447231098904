import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-subscription-benefits',
  templateUrl: './subscription-benefits.component.html',
  styleUrl: './subscription-benefits.component.css'
})
export class SubscriptionBenefitsComponent {
  @Output() closePopup = new EventEmitter<void>();

  close() {
    this.closePopup.emit();
  }
}
