import { Component, OnInit } from '@angular/core';
import { ApiService, Product } from '../api.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
})
export class ProductComponent implements OnInit {
  activeTab: string = 'Product';
  products: Product[] = []; // Array to store the products
  filteredItems: Product[] = [];
  searchQuery: string = ''; // Variable to store the search term

  showPopup: boolean = false;
  editMode: boolean = false;
  selectedProduct: Product = {
    product_master_id: '',
    product_name: '',
    quantity: '',
    price: 0,
  };
  isPopupOpen: boolean = false; // Declare it here

  constructor(private apiService: ApiService, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.loadProducts(); // Fetch products when the component initializes
  }

  // Method to fetch products from the API
  loadProducts() {
    this.apiService.getProducts().subscribe(
      (data) => {
        this.products = data;
        this.filteredItems = data; // Initially, show all items
      },
      (error) =>
        this.snackBar.open('Error loading products', 'Close', {
          duration: 3000,
        })
    );
  }

  // Method to filter items based on search query
  filterItems() {
    if (this.searchQuery.trim()) {
      const lowerCaseQuery = this.searchQuery.toLowerCase();
      this.filteredItems = this.products.filter((product) => {
        const nameMatch = product.product_name
          .toLowerCase()
          .includes(lowerCaseQuery);

        // Convert quantity to string if it's a number or already a string
        const quantityString = product.quantity
          ? product.quantity.toString().toLowerCase()
          : '';
        const quantityMatch = quantityString.includes(lowerCaseQuery);

        // Convert price to string if it's a number or null
        const priceString =
          product.price !== null ? product.price.toString() : '';
        const priceMatch = priceString.includes(lowerCaseQuery);

        return nameMatch || quantityMatch || priceMatch;
      });
    } else {
      this.filteredItems = [...this.products]; // Reset to all items if no search query
    }
  }

  togglePopup() {
    this.editMode = false;
    this.resetForm();
    this.showPopup = !this.showPopup; // Toggle popup open/close state
  }

  resetForm() {
    // Correct the property name to selectedProduct
    this.selectedProduct = {
      product_master_id: '',
      product_name: '',
      quantity: '',
      price: 0,
    };
  }

  closePopup() {
    this.showPopup = false;
  }

  editProduct(product: Product) {
    this.editMode = true;
    this.selectedProduct = product;
    this.showPopup = true; // Use this to open the modal
  }

  handleProductUpdated(product: Product) {
    if (this.editMode) {
      this.apiService.updateProduct(product).subscribe(
        () => {
          this.snackBar.open('Product updated successfully', 'Close', {
            duration: 3000,
          });
          this.loadProducts();
        },
        (error) =>
          this.snackBar.open('Error updating product', 'Close', {
            duration: 3000,
          })
      );
    } else {
      this.apiService.createProduct(product).subscribe(
        () => {
          this.snackBar.open('Product added successfully', 'Close', {
            duration: 3000,
          });
          this.loadProducts();
        },
        (error) =>
          this.snackBar.open('Error adding product', 'Close', {
            duration: 3000,
          })
      );
    }
    this.togglePopup();
  }

  deleteProduct(productMasterId: string) {
    this.apiService.deleteProduct(productMasterId).subscribe(
      () => {
        this.snackBar.open('Product deleted successfully', 'Close', {
          duration: 3000,
        });
        this.loadProducts();
      },
      (error) =>
        this.snackBar.open('Error deleting product', 'Close', {
          duration: 3000,
        })
    );
  }

  openSnackBar(message: string, panelClass: string) {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.panelClass = [panelClass];
    this.snackBar.open(message, 'Close', config);
  }
}
