
<!-- header.html -->
<nav class="bg-white dark:bg-white w-full z-20 top-0 border-b border-gray-500">
  <div class="w-full min-w-[100%] mx-auto flex flex-col lg:flex-row items-center justify-between h-32  xl:p-14">
    

   <!-- Links, Logo, and Icons Container -->
<div class="w-full flex items-center justify-between">
  
 <!-- Logo - Always Visible -->
 <a href="/" class="logo-link flex justify-center items-center lg:justify-start w-full lg:w-1/3">
  <div class="w-full flex-shrink-0 flex justify-center items-center 2xl:justify-start h-[100px] md:h-[80px] lg:h-[57px]  md:w-[600px] lg:w-[650px] ml-[10px] lg:ml-[-80px]">
  <!-- Your logo goes here -->
  <img class="h-full w-full object-cover" src="/assets/images/Nirdhara Website Icons-03.png" alt="Logo img" />
</div>
</a>

</div>

    <!-- Burger Menu and Icons - Visible on small screens -->
    <div class="lg:hidden w-full flex justify-between items-center relative ml-5 mb-5">
      <!-- Burger Menu Button -->
  <button (click)="toggleMenu()" class="lg:hidden text-black">
    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
    </svg>
  </button>
    
    </div>

  </div>
</nav>


<!-- Sidebar Container -->
  <div class="flex flex-col font-poppins">


      <div id="Main" [ngClass]="{'translate-x-0': isMenuOpen, '-translate-x-full': !isMenuOpen}"
      class="fixed transform lg:translate-x-0 ease-in-out transition duration-500 z-30 flex justify-start items-start h-[calc(100vh-105px)]
       lg:h-[calc(100vh-129px)] lg:mt-0 w-full  lg:w-64 bg-custom-teal flex-col">
    

       <div class="mt-6 flex flex-col justify-start items-center px-4 w-full space-y-6 pb-5">
        <!-- Dashboard Tab -->
        <div class="w-full">
          <a
            href="/admin-dashboard"
            (click)="setActiveTab('Dashboard')"
            class="py-1.5 text-sm ease-nav-brand flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-custom-gray transition-colors cursor-pointer w-56 lg:w-full"
            [ngClass]="{ 'bg-white shadow-md': activeTab === 'Dashboard' }"
          >
            <div
              class="bg-custom-gold flex h-8 w-8 items-center justify-center rounded-lg text-center"
            >
              <i class="fas fa-home text-white text-base"></i>
            </div>
            <span class="ml-3">Dashboard</span>
          </a>
        </div>
      
        <!-- Customer Tab -->
        <div class="w-full">
          <a
            href="/customer"
            (click)="setActiveTab('Customer')"
            class="py-1.5 text-sm ease-nav-brand flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-custom-gray transition-colors cursor-pointer w-56 lg:w-full"
            [ngClass]="{ 'bg-white shadow-md': activeTab === 'Customer' }"
          >
            <div
              class="bg-custom-gold flex h-8 w-8 items-center justify-center rounded-lg text-center"
            >
              <i class="fas fa-user text-white text-base"></i>
            </div>
            <span class="ml-3">Customer</span>
          </a>
        </div>
      
        <!-- Product Tab -->
        <div class="w-full">
          <a
            href="/product"
            (click)="setActiveTab('Product')"
            class="py-1.5 text-sm ease-nav-brand flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-custom-gray transition-colors cursor-pointer w-56 lg:w-full"
            [ngClass]="{ 'bg-white shadow-md': activeTab === 'Product' }"
          >
            <div
              class="bg-custom-gold flex h-8 w-8 items-center justify-center rounded-lg text-center"
            >
              <i class="fas fa-truck text-white text-base"></i>
            </div>
            <span class="ml-3">Product</span>
          </a>
        </div>
      
        <!-- Subscription Tab -->
        <div class="w-full">
          <a
            href="/admin-subscription"
            (click)="setActiveTab('Subscription')"
            class="py-1.5 text-sm ease-nav-brand flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-custom-gray transition-colors cursor-pointer w-56 lg:w-full"
            [ngClass]="{ 'bg-white shadow-md': activeTab === 'Subscription' }"
          >
            <div
              class="bg-custom-gold flex h-8 w-8 items-center justify-center rounded-lg text-center"
            >
              <i class="fas fa-calendar-days text-white text-base"></i>
            </div>
            <span class="ml-3">Subscription</span>
          </a>
        </div>

        <div class="w-full">
          <!-- Logout Button -->
          <a
            (click)="logout()"
            class="py-1.5 text-sm ease-nav-brand flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-custom-gray transition-colors cursor-pointer w-56 lg:w-full"
            [ngClass]="{ 'bg-white shadow-md': activeTab === 'Logout' }"
          >
            <div
              class="bg-custom-gold flex h-8 w-8 items-center justify-center rounded-lg text-center"
            >
              <i class="fas fa-power-off text-white text-base"></i>
            </div>
            <span class="ml-3">Logout</span>
          </a>
        </div>

      </div>
      
</div>
</div>
