import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { AuthGuard } from './auth.guard';
import { CheckoutGuard } from './checkout.guard';
import { CartComponent } from './cart/cart.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ProductComponent } from './product/product.component';
import { CustomerComponent } from './customer/customer.component';
import { AdminSubscriptionComponent } from './admin-subscription/admin-subscription.component';
import { LoginPopupComponent } from './login-popup/login-popup.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ReturnPolicyComponent } from './return-policy/return-policy.component';
import { ShippingPolicyComponent } from './shipping-policy/shipping-policy.component';
import { JuiceDeliveryComponent } from './juice-delivery/juice-delivery.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { JuiceOptionsComponent } from './juice-options/juice-options.component';

const routes: Routes = [
  { path: '', component: HomeComponent }, // Default route
  { path: 'subscribe', component: SubscribeComponent },
  { path: 'subscription/:type', component: SubscriptionComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'email-verification', component: EmailVerificationComponent },
  { path: 'sidebar', component: SidebarComponent },
  { path: 'admin-dashboard', component: AdminDashboardComponent },
  { path: 'checkout', component: CheckoutComponent, canActivate: [CheckoutGuard] },
  { path: 'cart', component: CartComponent },
  { path: 'footer', component: FooterComponent },
  { path :'header', component:HeaderComponent},
  { path :'product', component:ProductComponent},
  { path :'customer', component:CustomerComponent},
  { path :'admin-subscription', component:AdminSubscriptionComponent},
  { path :'login-popup', component:LoginPopupComponent},
  { path :'T&C', component:TermsConditionsComponent},
  { path :'refund_policy', component:RefundPolicyComponent},
  { path :'privacy_policy', component:PrivacyPolicyComponent},
  { path :'return_policy', component:ReturnPolicyComponent},
  { path :'shipping_policy', component:ShippingPolicyComponent},
  { path :'juice-delivery', component:JuiceDeliveryComponent},
  { path :'about-us', component:AboutUsComponent},
  { path :'juice-options', component:JuiceOptionsComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
