<body class="dark:bg-white">

<app-above-header-no-text></app-above-header-no-text>
<app-header></app-header>

<!-- Main Content Section -->
<main class="flex flex-col">

    <div class="flex flex-col items-center justify-center text-center">
        <!-- Title -->
        <h1 class="text-3xl sm:text-4xl lg:text-5xl 2xl:text-custom-font-65 2xl:leading-custom-line-73 font-normal text-custom-gray mt-64 3xl:mt-72">
          <span class="font-times mr-3 lg:mr-4">Your</span>
          <span class="font-times italic mr-3 lg:mr-4">Nirdhara</span>
          <span class="font-times block sm:inline">Juice Delivery</span>
        </h1>
      
        <!-- Subtitle -->
        <h2 class="font-lato text-sm xl:text-lg 3xl:text-custom-font-22 leading-custom-line-38 font-normal text-custom-gray mt-8 3xl:mt-10 tracking-[0px]">
          Your favorite juice delivered to your door, exactly when you need it!
        </h2>
      
        <!-- Button -->
        <button class="bg-custom-gray hover:bg-gray-900 text-custom-text-color-button mt-12 font-normal lg:font-medium py-2 px-2 2xl:px-4 font-poppins text-xs lg:text-sm 2xl:text-lg tracking-[3.6px] text-nowrap w-fit">
          CHECK DELIVERY PINCODES
        </button>
      </div>
      
      <!-- Delivery Zones and Delivery Windows Section -->
<section class="py-12 mt-16 mb-5">
    <div>
      <!-- Flexbox for Side-by-Side Layout -->
      <div class="flex flex-col lg:flex-row gap-12 2xl:gap-20">
        <!-- Delivery Zones Box -->
        <div class="flex-1 bg-custom-gold p-8 text-center pb-12 xl:pb-20">
            <h1 class="text-3xl sm:text-4xl lg:text-5xl 2xl:text-[55px] 2xl:leading-custom-line-73 font-normal text-[#312E2C] mt-6 mb-8">
                <span class="font-times mr-3 lg:mr-4">Delivery</span>
                <span class="font-times italic mr-3 lg:mr-4">Zones</span>
              </h1>
          <p class="font-poppins mb-8 text-sm sm:text-base 2xl:text-custom-font-22 2xl:leading-custom-line-38 text-[#2F2C2B] max-w-[55ch] break-words mx-auto">
            Wondering if we deliver down to your neighborhood? Check out our delivery zones because we're spreading the juiciness all over Pune!
          </p>
          <p class="font-extrabold font-poppins mb-4 text-sm 2xl:text-lg text-[#2F2C2B]">
            INSERT PIN CODE TO CHECK IF DELIVERY IS AVAILABLE
          </p>
          <button class="mt-8 border-2 border-[#1D1A1A] text-custom-gray py-2 px-4 font-poppins text-sm 3xl:text-lg w-64 2xl:w-72 3xl:w-80">
            INSERT PINCODE
          </button>
        </div>
  
        <!-- Delivery Windows Box -->
        <div class="flex-1 bg-custom-gold p-8 text-center pb-12 xl:pb-20">
            <h1 class="text-3xl sm:text-4xl lg:text-5xl 2xl:text-[55px] 2xl:leading-custom-line-73 font-normal text-[#312E2C] mt-6 mb-8">
                <span class="font-times mr-3 lg:mr-4">Delivery</span>
                <span class="font-times italic mr-3 lg:mr-4">Windows</span>
              </h1>
              <p class="font-poppins mb-8 text-sm sm:text-base 2xl:text-custom-font-22 2xl:leading-custom-line-38 text-[#2F2C2B] max-w-[55ch] break-words mx-auto">
                We provide daily delivery service seven days a week, with one available delivery windows each day: 6:30 am to 10:00 am. To ensure delivery during your preferred time slot, you can place your order up to two weeks in advance. 
              </p>
              <p class="font-extrabold font-poppins mb-4 text-sm 2xl:text-lg text-[#2F2C2B]">
                DELIVERY CHARGES: NO CHARGES ON SUBSCRIPTION
              </p>
        </div>
      </div>
    </div>
  </section>
  
<section class="bg-custom-teal">
  <div class="flex flex-col items-center justify-center text-center mt-10 px-4">
    <!-- Title -->
    <h1 class="text-3xl sm:text-4xl lg:text-5xl 2xl:text-custom-font-65 2xl:leading-custom-line-73 font-normal text-custom-gray mt-10">
      <span class="font-times mr-3 lg:mr-4">Delivery</span>
      <span class="font-times italic mr-3 lg:mr-4">Measures</span>
    </h1>
  
    <!-- Subtitle -->
    <h2 class="font-poppins text-sm xl:text-lg 3xl:text-xl font-bold text-custom-gray mt-8 tracking-[2px] 3xl:tracking-[4px]">
      ENSURING TIMELY AND FRESH DELIVERY OF NIRDHARA JUICES
    </h2>
  
    <!-- Paragraph -->
    <p class="mt-12 max-w-[92ch] text-custom-gray text-custom-font-20 lg:text-custom-font-22 leading-custom-line-50 2xl:text-[28px] 2xl:leading-custom-line-60 font-times">
      At Nirdhara, we are committed to delivering the freshest juices straight to your doorstep, ensuring every sip is as refreshing and nutritious as intended. To achieve this, we have implemented a meticulous delivery process that prioritizes both punctuality and quality. Here’s how we ensure your juices arrive on time and in perfect condition:
    </p>
  </div>

<!-- Content Section -->
<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-16 px-6 lg:px-16 relative">
  <!-- Vertical Divider -->
  <div class="hidden md:block lg:hidden absolute inset-y-0 left-1/2 border-l border-gray-700"></div>
  <div class="hidden lg:block absolute inset-y-0 left-1/3 border-l border-gray-700"></div>
  <div class="hidden lg:block absolute inset-y-0 left-2/3 border-l border-gray-700"></div>

  <!-- Card 1 -->
  <div class="div-flex-center">
    <h3 class="text-heading-h3">
      Freshly Prepared Juices
    </h3>
    <p class="text-paragraph max-w-[55ch]">
      Our juices are made in order to guarantee maximum freshness. Each batch is prepared with the finest, locally-sourced fruits and vegetables, ensuring that you receive the highest quality product every time.
    </p>
  </div>

  <!-- Card 2 -->
  <div class="div-flex-center">
    <h3 class="text-heading-h3">
      State-of-the-Art Packaging
    </h3>
    <p class="text-paragraph max-w-[52ch]">
      We use advanced, eco-friendly packaging that preserves the freshness and flavor of our juices. Each bottle is securely sealed to prevent leaks and maintain the integrity of the juice during transit.
    </p>
  </div>

  <!-- Card 3 -->
  <div class="div-flex-center">
    <h3 class="text-heading-h3">
      Temperature-Controlled Logistics
    </h3>
    <p class="text-paragraph max-w-[52ch]">
      Our delivery process includes temperature-controlled storage and transportation. This ensures that the juices remain at the optimal temperature throughout the journey, preserving their freshness and nutritional value.
    </p>
  </div>

  <!-- Card 4 -->
  <div class="div-flex-center">
    <h3 class="text-heading-h3">
      Efficient Delivery Network
    </h3>
    <p class="text-paragraph max-w-[50ch]">
      We have partnered with reliable and efficient delivery services to ensure timely deliveries. Our logistics team carefully plans and monitors each delivery route to minimize delays and ensure that your order arrives within the designated time frame.
    </p>
  </div>

  <!-- Card 5 -->
  <div class="div-flex-center">
    <h3 class="text-heading-h3">
      Flexible Delivery Options
    </h3>
    <p class="text-paragraph max-w-[52ch]">
      Understanding that schedules can change, we offer flexible delivery options. You can choose a delivery window that best suits your needs, and we provide options for rescheduling if necessary.
    </p>
  </div>

  <!-- Card 6 -->
  <div class="div-flex-center">
    <h3 class="text-heading-h3">
      Customer Communication
    </h3>
    <p class="text-paragraph max-w-[50ch]">
      Our customer service team is always available to assist with any delivery-related inquiries. Whether you need to update your delivery instructions or have questions about your order, we are here to help.
    </p>
  </div>

  <!-- Card 7 -->
  <div class="div-flex-center">
    <h3 class="text-heading-h3">
      Quality Assurance Checks
    </h3>
    <p class="text-paragraph max-w-[50ch]">
      Before dispatch, each order undergoes a thorough quality assurance check to ensure that it meets our high standards. This includes verifying the freshness of the juice and the integrity of the packaging.
    </p>
  </div>

  <!-- Card 8 -->
  <div class="div-flex-center">
    <h3 class="text-lg lg:text-2xl 2xl:text-[34px] text-custom-gray mb-5 font-times lg:leading-custom-line-38 2xl:leading-custom-line-50 max-w-[32ch]">
      By following these stringent measures, we aim to provide you with a seamless experience every time you order from us. We are dedicated to delivering the freshest, healthiest juices right to your door, so you can enjoy without any hassle.
    </h3>
  </div>


  <!-- Card 9 -->
  <div class="div-flex-center">
    <h3 class="text-heading-h3">
      Satisfaction Guarantee
    </h3>
    <p class="text-paragraph max-w-[50ch]">
      Your satisfaction is our top priority. If, for any reason, you are not completely satisfied with the freshness or quality of your juice upon delivery, please contact us within 24 hours for a replacement or refund.
    </p>
  </div>

</div>
  

  <h1 class="text-3xl sm:text-4xl 2xl:text-6xl 2xl:leading-custom-line-73 font-normal text-custom-gray text-center mt-10 p-2 md:p-0">
    <span class="font-times mr-3 lg:mr-4">Thank you for choosing</span>
    <span class="font-times italic mr-3 lg:mr-4">Nirdhara.</span><br/>
    <span class="font-times block sm:inline mr-3 lg:mr-4">We look forward to serving you with</span>
    <span class="font-times italic mr-3 lg:mr-4 text-nowrap">the best fresh juice</span><br/>
    <span class="font-times block sm:inline">delivery service available.</span>
  </h1>
  <div class="h-40 lg:h-60"></div>
</section>


</main>
</body>
<app-footer-bg-green></app-footer-bg-green>
