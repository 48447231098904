<app-above-header-no-text></app-above-header-no-text>
<app-header></app-header>
<div class="dark:bg-white">
<main class="py-0 flex flex-col lg:flex-row xl:h-[calc(100vh-23px-144px)]">
  <section class="w-full lg:w-1/2 flex flex-col items-center justify-center text-center gap-6 lg:gap-10 2xl:gap-12 px-4 lg:px-8 py-8">
    <h1 class="text-3xl sm:text-4xl lg:text-5xl xl:text-6xl 3xl:text-7xl text-gray-800  2xl:mt-20">
      <span class="font-times">Experience </span> <span class="font-times italic">Freshness</span>&nbsp; <br class="hidden xl:inline" /> <span class="font-times">Delivered</span>
    </h1>
    <p class="text-lg sm:text-xl 2xl:text-[22px] leading-custom-line-38 text-custom-gray font-normal font-lato 3xl:px-20">
      At Nirdhara, we believe in bringing the freshest, most delicious juices straight to your doorstep. Sourced from local farmers and crafted with care, our juices are a testament to our commitment to quality, health, and sustainability.
    </p>

    <div class="flex flex-col items-center justify-center text-center gap-4">
      <!-- <a href="/subscribe"> -->
      <button [routerLink]="['/subscribe']" class="bg-custom-gray hover:bg-gray-900 text-custom-text-color-button font-normal py-2.5 px-2 2xl:px-5 font-poppins text-xs lg:text-sm tracking-[3.6px] w-60 lg:w-80">
        BUILD YOUR SUBSCRIPTION
      </button>
    <!-- </a> -->
      <h1 class="text-2xl 2xl:text-3xl text-gray-800 ">
        <span class="font-times italic">OR</span> 
      </h1>
      <button class="bg-custom-gray hover:bg-gray-900 text-custom-text-color-button font-normal py-2.5 px-2 2xl:px-5 font-poppins text-xs lg:text-sm tracking-[3.6px] w-60 lg:w-80">
        SINGLE DELIVERY
      </button>
    </div>
  
    <h2 class="text-lg lg:text-xl 2xl:text-[25px] 2xl:leading-custom-line-65 font-poppins text-custom-gray ">
      Welcome to <span class="italic">Nirdhara</span> - Hand-bottled in Pune, India
    </h2>
  </section>
  <section class="w-full lg:w-1/2 h-[500px] lg:h-[700px] xl:h-full">
      <img class="h-full w-full object-cover" src="../../assets/images/benigno-hoyuela-E1ZwXiBD7LA-unsplash.jpg" alt="Orange img" />
  </section>
</main>

<div class="w-full h-auto relative pb-[62.6%]">
  <img
    src="../../assets/images/route_image.png"
    alt="Route Background"
    class="absolute inset-0 w-full h-full object-cover"
  />
</div>



  <!-- <div class="bg-[#CCD56A] min-h-full lg:min-h-screen overflow-x-hidden relative">
    <div class="route-background relative lg:min-h-screen">
        <div class="step-container absolute p-4 max-w-lg 2xl:mt-[15px] 2xl:ml-[550px] xl:mt-[10px] xl:ml-[350px]">
            <h3 class="font-bold font-poppins text-2xl text-[#2F2C2B]">Choose your subscription plan</h3>
            <p class="font-poppins text-xl text-[#2F2C2B]">Craft a subscription as per your schedule. A daily juice delivery or a tailored schedule, our plans are flexible.</p>
            <div class="number-container absolute font-lato text-5xl text-[#302E2C] 2xl:mt-[30px] 2xl:left-[-5px] xl:mt-[20px] xl:left-[-5px]">
              1.
          </div>  
        </div>
        <div class="step-container absolute max-w-5xl">
          <h1 class="text-3xl 2xl:text-6xl xl:text-5xl text-[#312E2C] 2xl:mt-[250px] 2xl:ml-[-100px] xl:mt-[230px] xl:ml-[-110px]">
            <span class="font-times">Healthy doesn’t have to be hard.<br/> We make it  </span> <span class="font-times italic">easy!</span> 
          </h1>
        </div>
        <div class="step-container absolute p-4 text-black max-w-lg 2xl:mt-[184px] 2xl:ml-[1030px] xl:mt-[160px] xl:ml-[550px]">
            <h3 class="font-bold font-poppins text-2xl text-[#2F2C2B] mt-5 ml-48">Select your juice</h3>
            <p class="font-poppins text-xl text-[#2F2C2B] ml-48">Dive into a world of fresh, flavorful options.</p>
            <div class="number-container absolute font-lato text-5xl text-[#302E2C] 2xl:mt-[-160px] 2xl:ml-[230px] xl:mt-[-150px] xl:ml-[240px]">
              2.
          </div> 
        </div>
       <div class="step-container absolute p-4 text-black max-w-xl 2xl:mt-[500px] 2xl:ml-[1030px] xl:mt-[450px] xl:ml-[650px]">
            <h3 class="font-bold font-poppins text-2xl text-[#2F2C2B]">Check your delivery zone</h3>
            <p class="font-poppins text-xl text-[#2F2C2B]">Explore your delivery options! Take a peek at our delivery zones, time slots, and confirm if we’re delivering to your neighborhood.</p>
            <div class="number-container absolute font-lato text-5xl text-[#302E2C] 2xl:mt-[-160px] 2xl:ml-[65px] xl:mt-[-180px] xl:ml-[35px]">
              3.
          </div>
        </div>
         <div class="step-container absolute p-4 text-black max-w-sm 2xl:mt-[500px] 2xl:ml-[170px] xl:mt-[450px] xl:ml-[110px]">
            <h3 class="font-bold font-poppins text-2xl text-[#2F2C2B]">Add in your personal details</h3>
            <p class="font-poppins text-xl text-[#2F2C2B]">Sign up with your delivery address and phone number, then pick your preferred payment method.</p>
          <div class="number-container absolute font-lato text-5xl text-[#302E2C] 2xl:mt-[-185px] 2xl:ml-[90px] xl:mt-[-180px] xl:ml-[40px]">
              4.
          </div>
        </div>
        <div class="step-container absolute p-4 text-black 2xl:max-w-xl max-w-sm 2xl:mt-[715px] 2xl:ml-[895px] xl:mt-[645px] xl:ml-[600px]">
            <h3 class="font-bold font-poppins text-2xl text-[#2F2C2B]">We ship. You sip!</h3>
            <p class="font-poppins text-xl text-[#2F2C2B]">Simple as that! Unwind, and enjoy your freshly delivered juice.</p>
            <div class="number-container absolute font-lato text-5xl text-[#302E2C] 2xl:mt-[-45px] 2xl:ml-[-125px] xl:mt-[-75px] xl:ml-[-125px]">
              5.
          </div>
        </div> 
    </div>
</div>
   -->
  
  
  

  <!-- New Section -->
  <div class="px-16 mt-16">
    <h1 class="text-3xl sm:text-4xl lg:text-5xl 2xl:text-custom-font-65 2xl:leading-custom-line-73 font-normal text-custom-gray  text-center">
      <span class="font-times mr-3 lg:mr-4">Our</span>
      <span class="font-times italic mr-3 lg:mr-4">subscription</span>
      <span class="font-times block sm:inline">options</span>
    </h1>
    <h1 class="font-poppins text-sm xl:text-lg 3xl:text-xl font-extrabold text-custom-gray flex items-center justify-center mt-6 tracking-[2px] 3xl:tracking-[4px]">
      ADD, PAUSE OR CANCEL YOUR SUBSCRIPTION, ANYTIME!
    </h1>
    
    <div class="flex flex-wrap mt-16">
      <!-- Daily Subscription -->
      <div class="w-full md:w-1/2 lg:w-1/3 pb-6">
        <div class="flex flex-col items-center text-center justify-between h-full md:border-r border-[#707070] pb-6">
          <!-- Image -->
          <div class="flex justify-center items-center w-full mb-5 px-5">
            <img class="w-full h-auto" src="../../assets/images/juice-bottle.png" alt="Juice Bottle" />
          </div>
    
          <!-- Headings -->
          <div class="flex flex-col items-center text-center mt-5">
            <h2 class="text-sm xl:text-lg 3xl:text-custom-font-20 lg:leading-custom-line-30 font-poppins font-extrabold text-custom-gray tracking-widest mb-10 2xl:mb-20">POPULAR</h2>
            <h1 class="text-3xl lg:text-[30px] 2xl:text-[45px] text-custom-gray">
              <span class="font-times block mb-2">DAILY</span>
              <span class="font-times italic">subscription</span>
            </h1>
          </div>
    
          <!-- Paragraph -->
          <div class="mt-10">
            <p class="text-sm 3xl:text-custom-font-20 2xl:leading-custom-line-38 font-lato px-4 3xl:px-4 text-[#343434]">
              With our Daily Subscription, you can select your favorite juices and have them delivered to your doorstep every day. Enjoy the convenience of fresh juice daily.          </p>
          </div>
    
          <!-- Button -->
          <button (click)="startSubscription('daily')" class="mt-16 border-2 border-[#1D1A1A] text-custom-gray py-2 px-4 font-poppins text-sm 3xl:text-lg w-64 lg:w-60 2xl:w-72 3xl:w-80">
            START YOUR SUBSCRIPTION
          </button>
        </div>
      </div>
    
      <!-- Alternate Day Subscription -->
      <div class="w-full md:w-1/2 lg:w-1/3 pb-6">
        <div class="flex flex-col items-center text-center justify-between h-full lg:border-r border-[#707070] pb-6">
          <!-- Image -->
          <div class="flex justify-center items-center w-full mb-5 px-5">
            <img class="w-full h-auto" src="../../assets/images/juice-bottle.png" alt="Juice Bottle" />
          </div>
    
          <!-- Headings -->
          <div class="flex flex-col items-center text-center mt-5">
            <h2 class="text-sm xl:text-lg 3xl:text-custom-font-20 lg:leading-custom-line-30 font-poppins font-extrabold text-custom-gray tracking-widest mb-10 2xl:mb-20">BEST SELLER</h2>
            <h1 class="text-3xl lg:text-[30px] 2xl:text-[45px] text-custom-gray">
              <span class="font-times block mb-2">ALTERNATE DAY</span>
              <span class="font-times italic">subscription</span>
            </h1>
          </div>
    
          <!-- Paragraph -->
          <div class="mt-10">
            <p class="text-sm 3xl:text-custom-font-20 2xl:leading-custom-line-38 font-lato px-6 3xl:px-4 text-[#343434]">
              Our Alternate Day Subscription allows you to choose your preferred juices and receive deliveries every other day. Perfect for those who enjoy fresh juice regularly but not necessarily every day.          </p>
          </div>
    
          <!-- Button -->
          <button (click)="startSubscription('alternate-day')" class="mt-8 border-2 border-[#1D1A1A] text-custom-gray py-2 px-4 font-poppins text-sm 3xl:text-lg w-64 lg:w-60 2xl:w-72 3xl:w-80">
            START YOUR SUBSCRIPTION
          </button>
        </div>
      </div>
    
      <!-- Weekly Subscription -->
      <div class="w-full md:w-1/2 lg:w-1/3 pb-6">
        <div class="flex flex-col items-center text-center justify-between h-full pb-6">
          <!-- Image -->
          <div class="flex justify-center items-center w-full mb-5 px-5">
            <img class="w-full h-auto" src="../../assets/images/juice-bottle.png" alt="Juice Bottle" />
          </div>
    
          <!-- Headings -->
          <div class="flex flex-col items-center text-center mt-5">
            <h2 class="text-sm xl:text-lg 3xl:text-custom-font-20 lg:leading-custom-line-30 font-poppins font-extrabold text-custom-gray tracking-widest mb-10 2xl:mb-20">MOST LOVED</h2>
            <h1 class="text-3xl lg:text-[30px] 2xl:text-[45px] text-custom-gray">
              <span class="font-times block mb-2">WEEKLY</span>
              <span class="font-times italic">subscription</span>
            </h1>
          </div>
    
          <!-- Paragraph -->
          <div class="mt-10">
            <p class="text-sm 3xl:text-custom-font-20 2xl:leading-custom-line-38 font-lato px-4 3xl:px-4 text-[#343434]">
              Customize your juice experience with our Weekly Subscription. Choose your favorite juices and select the specific days you want them delivered. Enjoy flexibility and personalization to fit your schedule.          </p>
          </div>
    
          <!-- Button -->
          <button (click)="startSubscription('weekly')" class="mt-8 border-2 border-[#1D1A1A] text-custom-gray py-2 px-4 font-poppins text-sm 3xl:text-lg w-64 lg:w-60 2xl:w-72 3xl:w-80">
            START YOUR SUBSCRIPTION
          </button>
      </div>
    </div>
  </div>
  
  
  
  </div>

<main class="flex flex-col lg:flex-row h-[850px] py-0 mt-10 lg:h-[850px] 2xl:h-[950px]">
  <section class="w-full lg:w-1/2 h-[500px] lg:h-full">
    <img class="h-full w-full object-cover" src="../../assets/images/benigno-hoyuela-E1ZwXiBD7LA-unsplash.jpg" alt="Orange img" />
  </section>
  <section class="bg-[#CCD56A] lg:w-1/2 w-full flex flex-col justify-center gap-8 lg:gap-16">
    <h1 class="flex justify-center text-3xl sm:text-4xl xl:text-5xl 3xl:text-7xl text-custom-gray">
      <span class="font-times italic">What makes us different?</span>
    </h1>
    <p class="text-lg sm:text-xl 2xl:text-[28px] pl-5 lg:pr-20 font-times text-center text-custom-gray" style="line-height: 2;">
      We take pride in sourcing our fruits directly from local farmers who share our commitment to quality and sustainability. By doing so, we not only support our local communities but also ensure that you receive the freshest produce available. Our health-focused philosophy means that our juices are freshly squeezed without any additives or preservatives.
    </p>
  </section>
</main>

<app-our-bestsellers></app-our-bestsellers>

 <!-- Why Choose Nirdhara Section -->
<main class="bg-[#CAE0DD] py-0 flex flex-col justify-center items-center mt-10 3xl:mt-0">
  <!-- Content Section -->
  <section class="w-full flex flex-col justify-center gap-0 px-4 3xl:px-20">
    <h1 class="font-sans text-3xl sm:text-5xl 3xl:text-[65px] font-medium text-gray-800 flex items-center justify-center mt-10">
      <span class="font-times mr-2 sm:mr-4">Why</span>
      <span class="font-times">Choose</span>&nbsp;
      <span class="font-times italic mr-4">Nirdhara?</span>
    </h1>
    
    <!-- First Row Images and Content -->
    <div class="flex flex-col lg:flex-row justify-between mt-8">
      <!-- Image 1 -->
      <div class="flex flex-col items-center w-full lg:w-1/3 mb-12 lg:mb-0 lg:mr-4">
        <div class="relative bg-[#CCD56A] rounded-full w-44 h-44 2xl:w-48 2xl:h-48  3xl:w-56 3xl:h-56">
          <div class="w-44 h-44 2xl:w-48 2xl:h-48 3xl:w-56 3xl:h-56 flex justify-center items-center">
            <!-- Image goes here -->
            <img class="w-full h-auto scale-[200%]" src="../../assets/images/Nirdhara Website Icons-04.png" alt="Juice Bottle" />
          </div>
        </div>
        <div class="text-center mt-6">
          <h1 class="font-sans text-xl 2xl:text-2xl font-bold text-black">
            HANDPICKED AND FRESH
          </h1>
          <p class="text-sm xl:text-lg 2xl:text-xl mt-5 text-gray-900 3xl:px-10 tracking-[1px]">
            Our fruits are handpicked and harvested with care, ensuring peak freshness and taste.
          </p>
        </div>
      </div>

      <!-- Image 2 -->
      <div class="flex flex-col items-center w-full lg:w-1/3 mb-12 lg:mb-0 lg:mr-4">
        <div class="relative bg-[#CCD56A] rounded-full w-44 h-44 2xl:w-48 2xl:h-48  3xl:w-56 3xl:h-56">
          <div class="w-44 h-44 2xl:w-48 2xl:h-48  3xl:w-56 3xl:h-56 flex justify-center items-center">
            <!-- Image goes here -->
            <img class="w-full h-auto scale-[200%]" src="../../assets/images/Nirdhara Website Icons-05.png" alt="Juice Bottle" />
          </div>
        </div>
        <div class="text-center mt-6">
          <h1 class="font-sans text-xl 2xl:text-2xl font-bold text-black">
            ONLY THE GOOD STUFF
          </h1>
          <p class="text-sm xl:text-lg 2xl:text-xl mt-5 text-gray-900 2xl:px-2 3xl:px-[3rem] tracking-[1px]">
            Our commitment to your well-being means no added sugars, preservatives, or artificial flavors.
          </p>
        </div>
      </div>

      <!-- Image 3 -->
      <div class="flex flex-col items-center w-full lg:w-1/3 mb-12 lg:mb-0 lg:mr-4">
        <div class="relative bg-[#CCD56A] rounded-full w-44 h-44 2xl:w-48 2xl:h-48  3xl:w-56 3xl:h-56">
          <div class="w-44 h-44 2xl:w-48 2xl:h-48  3xl:w-56 3xl:h-56 flex justify-center items-center">
            <!-- Image goes here -->
            <img class="w-full h-auto scale-[200%]" src="../../assets/images/Nirdhara Website Icons-06.png" alt="Juice Bottle" />
          </div>
        </div>
        <div class="text-center mt-6">
          <h1 class="font-sans text-xl 2xl:text-2xl font-bold text-black">
            HYGIENE STANDARDS
          </h1>
          <p class="text-sm xl:text-lg 2xl:text-xl mt-5 text-gray-900 2xl:px-5 3xl:px-16 tracking-[1px]">
            We ensure impeccable hygiene standards from farm to bottle, guaranteeing safe and pure juices.
          </p>
        </div>
      </div>
    </div>

    <!-- Second Row Images and Content -->
    <div class="flex flex-col lg:flex-row justify-between xl:px-52 mt-8">
      <!-- Image 4 -->
      <div class="flex flex-col items-center w-full lg:w-1/2 mb-8 lg:mb-0 lg:mr-4">
        <div class="relative bg-[#CCD56A] rounded-full w-44 h-44 2xl:w-48 2xl:h-48  3xl:w-56 3xl:h-56">
          <div class="w-44 h-44 2xl:w-48 2xl:h-48  3xl:w-56 3xl:h-56 flex justify-center items-center">
            <!-- Image goes here -->
            <img class="w-full h-auto scale-[200%]" src="../../assets/images/Nirdhara Website Icons-07.png" alt="Juice Bottle" />
          </div>
        </div>
        <div class="text-center mt-6">
          <h1 class="font-sans text-xl 2xl:text-2xl font-bold text-black">
            SUSTAINABLE
          </h1>
          <p class="text-sm xl:text-lg 2xl:text-xl mt-5 text-gray-900 px-4 lg:px-16 xl:px-4 3xl:px-20 tracking-[1px]">
            Our sustainable packaging materials help us reduce our environmental footprints.
          </p>
        </div>
      </div>

      <!-- Image 5 -->
      <div class="flex flex-col items-center w-full lg:w-1/2 mb-8 lg:mb-0 lg:ml-4">
        <div class="relative bg-[#CCD56A] rounded-full w-44 h-44 2xl:w-48 2xl:h-48  3xl:w-56 3xl:h-56">
          <div class="w-44 h-44 2xl:w-48 2xl:h-48  3xl:w-56 3xl:h-56 flex justify-center items-center">
            <!-- Image goes here -->
            <img class="w-full h-auto scale-[200%]" src="../../assets/images/Nirdhara Website Icons-08.png" alt="Juice Bottle" />
          </div>
        </div>
        <div class="text-center mt-6">
          <h1 class="font-sans text-xl 2xl:text-2xl font-bold text-black">
            CONVENIENCE FIRST
          </h1>
          <p class="text-sm xl:text-lg 2xl:text-xl mt-5 text-gray-900 px-4 lg:px-16 xl:px-4 3xl:px-24 tracking-[1px]">
            Our commitment to your well-being means no added sugars, preservatives, or artificial flavors.
          </p>
        </div>
      </div>
    </div>
  </section>
</main>
</div>

<app-footer-bg-green></app-footer-bg-green>


