<app-above-header-no-text></app-above-header-no-text>
<app-header></app-header>

<body class="dark:bg-white">
    <main class="py-0 flex flex-col lg:flex-row xl:h-[calc(100vh-23px-144px)]">
      <section class="w-full lg:w-1/2 flex flex-col items-center justify-center text-center px-4 lg:px-8 py-8 lg:mt-20 xl:mt-36 2xl:mt-12 3xl:mt-16">
        <h1 class="text-3xl sm:text-4xl lg:text-5xl xl:text-6xl 3xl:text-7xl text-gray-800  2xl:mt-28">
          <span class="font-times">About </span> <span class="font-times italic">Our</span> <span class="font-times"> Journey</span>
        </h1>
        <div class="max-w-[65ch] 2xl:max-w-[80ch] flex flex-col text-center items-center gap-3 lg:mt-10 2xl:mt-8 3xl:mt-10">
        <h2 class="font-poppins text-sm xl:text-lg 3xl:text-custom-font-22 leading-custom-line-38 font-extrabold text-custom-gray flex mt-5">
            The Founder’s vision
        </h2>
        <p class="text-lg sm:text-[18px] sm:leading-custom-line-30 2xl:text-[22px] 2xl:leading-custom-line-38 text-custom-gray font-normal font-lato">
            Our founder’s simple yet profound vision led us to embark this journey of Nirdhara – to make the finest natural and organic produce accessible to all. While we prioritize health, convenience, and environmental stewardship, we welcome you to join us in this journey towards a fresher, healthier, and more sustainable way of enjoying nature’s goodness.
        </p>
    </div>
        <div class="flex flex-col items-center justify-center text-center mt-12 3xl:mt-16">
          <!-- <a href="/subscribe"> -->
          <button [routerLink]="['/subscribe']" class="bg-custom-gray hover:bg-gray-900 text-custom-text-color-button font-normal py-2.5 px-2 2xl:px-5 font-poppins text-xs lg:text-sm tracking-[3.6px] w-fit">
            BUILD YOUR SUBSCRIPTION
          </button>

        </div>
      
        <h2 class="text-lg lg:text-xl 2xl:text-[25px] 2xl:leading-custom-line-65 font-poppins text-custom-gray mt-16 2xl:mt-10">
          Welcome to <span class="italic">Nirdhara</span> - Hand-bottled in Pune, India
        </h2>
      </section>
      <section class="w-full lg:w-1/2 h-[500px] lg:h-[700px] xl:h-full">
          <img class="h-full w-full object-cover" src="../../assets/images/pomegranate.png" alt="pomegranate img" />
      </section>
    </main>

    <div class="flex flex-col lg:flex-row h-[1000px] py-0 lg:h-[850px] 2xl:h-[950px] bg-custom-gold">
      <section class="w-full lg:w-1/2 h-[500px] lg:h-full flex justify-center items-center text-left">
        <h1 class="text-3xl sm:text-4xl xl:text-5xl 3xl:text-custom-font-65 3xl:leading-custom-line-73 text-custom-gray max-w-[20ch]">
          <span class="font-times">What led to the innovation of </span> <span class="font-times italic">Nirdhara?</span>
        </h1>
      </section>
      <section class="lg:w-1/2 w-full flex flex-col justify-center px-6 lg:px-12 font-poppins">
        <div>
          <h2 class="text-sm sm:text-[18px] leading-custom-line-38 3xl:text-custom-font-25 3xl:leading-custom-line-73 font-extrabold text-custom-gray">Health concerns prompted our initiative.</h2>
          <p class="max-w-[65ch] text-xs sm:text-[16px] leading-custom-line-30 lg:mb-5 2xl:text-custom-font-20 2xl:leading-custom-line-30 text-custom-gray">
            Packaged juices often contain preservatives and added sugars, posing health risks. Recognizing this, Nirdhara took a stand, offering fresh, organic juices enriched with vital nutrients to promote wellness.
          </p>
        </div>
        <div>
          <h2 class="text-sm sm:text-[18px] leading-custom-line-38 3xl:text-custom-font-25 3xl:leading-custom-line-73 font-extrabold text-custom-gray">Lack of hygiene and sanitation standards</h2>
          <p class="max-w-[63ch] text-xs sm:text-[16px] leading-custom-line-30 lg:mb-5 2xl:text-custom-font-20 2xl:leading-custom-line-30 text-custom-gray">
            Traditional juice vendors impose a lack of hygiene and sanitation standards.<br/> In response, we devised a meticulous, direct handling process from production to delivery, ensuring uncompromising hygiene standards.
          </p>
        </div>
        <div>
          <h2 class="text-sm sm:text-[18px] leading-custom-line-38 3xl:text-custom-font-25 3xl:leading-custom-line-73 font-extrabold text-custom-gray">Demand for convenience and accessibility</h2>
          <p class="max-w-[60ch] text-xs sm:text-[16px] leading-custom-line-30 lg:mb-5 2xl:text-custom-font-20 2xl:leading-custom-line-30 text-custom-gray">
            The demand for convenience and accessibility spurred innovation. Through our subscription-based ordering and delivery system, Nirdhara provides unparalleled convenience, bringing freshness to our juices to customers’ doorsteps seamlessly and efficiently.
          </p>
        </div>
        <div>
          <h2 class="text-sm sm:text-[18px] leading-custom-line-38 3xl:text-custom-font-25 3xl:leading-custom-line-73 font-extrabold text-custom-gray">Environmental responsibility</h2>
          <p class="max-w-[65ch] text-xs sm:text-[16px] leading-custom-line-30 lg:mb-5 2xl:text-custom-font-20 2xl:leading-custom-line-30 text-custom-gray">
            Lastly, environmental responsibility is ingrained in our ethos. Nirdhara is committed to eco-friendly packaging and waste reduction. It’s a harmonious balance between convenience and environmental stewardship, reflecting our dedication to holistic well-being.
          </p>
        </div>
      </section>
      
    </div>

    <app-our-bestsellers></app-our-bestsellers>

    <div class="bg-custom-teal px-16">
      <div class="flex flex-col items-center justify-center text-center mt-10 px-4">
        <!-- Title -->
        <h1 class="text-3xl sm:text-4xl lg:text-5xl 2xl:text-custom-font-65 2xl:leading-custom-line-73 font-normal text-custom-gray mt-10">
          <span class="font-times mr-3 lg:mr-4">Our Commitment to</span>
          <span class="font-times italic mr-3 lg:mr-4">Sustainability</span>
        </h1>
      
        <!-- Subtitle -->
        <h2 class="font-poppins text-sm xl:text-lg 3xl:text-xl font-bold text-custom-gray mt-8 tracking-[2px] 3xl:tracking-[4px]">
          THANK YOU FOR SUPPORTING NIRDHARA AND OUR COMMITMENT TO SUSTAINABILITY.
        </h2>
      </div>
    
      
      <div class="flex flex-wrap mt-16">
        <!-- Column 1 -->
        <div class="w-full md:w-1/2 lg:w-1/3 pb-6">
          <div class="flex flex-col items-center text-center justify-start h-full md:border-r border-[#707070] pb-6 gap-y-10">
            <!-- Image -->
            <div class="flex justify-center items-center w-full mb-5 px-5">
              <img class="w-full h-auto" src="../../assets/images/about-us-1-img.png" alt="Juice Bottle" />
            </div>
      
            <!-- Card 1 -->
            <div class="div-flex-center">
              <h3 class="text-heading-h3">Locally-Sourced Ingredients</h3>
                  <p class="text-paragraph max-w-[50ch]">
                    We partner with local farmers to source the freshest, highest-quality fruits and vegetables. By supporting local agriculture, we reduce our carbon footprint and promote sustainable farming practices that benefit the community and the environment.
                  </p>
            </div>

            <!-- Card 4 -->
            <div class="div-flex-center">
              <h3 class="text-heading-h3">Bio Pits for Recycling Waste</h3>
                  <p class="text-paragraph max-w-[50ch]">
                    To further our commitment to sustainability, we utilize bio pits to recycle organic waste generated during juice production. These bio pits efficiently break down organic matter, turning waste into valuable compost that can be used to enrich the soil and support local agriculture.
                  </p>
            </div>

            <!-- Card 7 -->
            <div class="div-flex-center">
              <h3 class="text-heading-h3">Continuous Improvement</h3>
              <p class="text-paragraph max-w-[50ch]">
                Sustainability is an ongoing journey. We are committed to continuously improving our practices, staying informed about the latest sustainability innovations, and implementing new strategies to further reduce our environmental footprint.
              </p>
            </div>
          </div>
        </div>
      
        <!-- Column 2 -->
        <div class="w-full md:w-1/2 lg:w-1/3 pb-6">
          <div class="flex flex-col items-center text-center justify-start h-full lg:border-r border-[#707070] pb-6 gap-y-10">
            <!-- Image -->
            <div class="flex justify-center items-center w-full mb-5 px-5">
              <img class="w-full h-auto" src="../../assets/images/about-us-2-img.png" alt="Juice Bottle" />
            </div>
      
            <!-- Card 2 -->
            <div class="div-flex-center">
              <h3 class="text-heading-h3">Eco-Friendly Packaging</h3>
              <p class="text-paragraph max-w-[50ch]">
                Our commitment to the environment extends to our packaging. We use recyclable and biodegradable materials to ensure that our packaging has minimal impact on the planet. Our bottles are made from eco-friendly materials, and we encourage our customers to recycle or reuse them.
              </p>
            </div>

            <!-- Card 5 -->
            <div class="div-flex-center">
              <h3 class="text-heading-h3">Sustainable Transportation</h3>
              <p class="text-paragraph max-w-[50ch]">
                We have optimized our delivery routes to reduce fuel consumption and emissions. Our logistics partners are committed to using eco-friendly vehicles, and we are exploring options for electric and hybrid delivery vehicles to further lessen our environmental impact.
              </p>
            </div>

            <!-- Card 8 -->
            <div class="div-flex-center">
              <h3 class="text-lg lg:text-2xl 2xl:text-[34px] text-custom-gray mb-5 font-times lg:leading-custom-line-38 2xl:leading-custom-line-50 max-w-[546px]">
                  At 
                  <span class="italic">Nirdhara,</span> we are dedicated to making a difference through sustainable practices. We invite you to join us on this journey towards a 
                  <span class="italic">healthier, greener future.</span>
              </h3>
            </div>
          </div>
        </div>
      
        <!-- Column 3 -->
        <div class="w-full md:w-1/2 lg:w-1/3 pb-6">
          <div class="flex flex-col items-center text-center justify-start h-full pb-6 gap-y-10">
            <!-- Image -->
            <div class="flex justify-center items-center w-full mb-5 px-5">
              <img class="w-full h-auto" src="../../assets/images/about-us-3-img.png" alt="Juice Bottle" />
            </div>
      
                  <!-- Card 3 -->
          <div class="div-flex-center">
            <h3 class="text-heading-h3">Minimal Waste Production</h3>
            <p class="text-paragraph max-w-[545px]">
              We strive to minimize waste throughout our production
              process. By carefully planning and managing our resources, we reduce food waste and ensure that any surplus is repurposed or composted, contributing to a circular economy.
            <br><br>
            </p>
            
          </div>

          <!-- Card 6 -->
          <div class="div-flex-center">
            <h3 class="text-heading-h3">Water Conservation</h3>
            <p class="text-paragraph max-w-[52ch]">
              Water is a precious resource, and we use it responsibly in our operations. Our production processes are designed to minimize water usage, and we employ water-saving techniques to ensure that we are doing our part to conserve this vital resource.
            </p>
          </div>

          <!-- Card 9 -->
          <div class="div-flex-center">
            <h3 class="text-heading-h3">Transparency and Accountability</h3>
            <p class="text-paragraph max-w-[50ch]">
              We believe in being transparent about our sustainability efforts. We regularly update our customers on our progress and welcome feedback on how we can further enhance our sustainability initiatives.
            </p>
          </div>
        </div>
      </div>
    </div>  
    
    </div>

</body>
<app-footer-bg-green></app-footer-bg-green>
