import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgModel, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../api.service'; // Import ApiService

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  otpSent: boolean = false;
  otpVerified: boolean = false;
  resetPassword: boolean = false;
  successMessage: string | null = null;
  errorMessage: string | null = null;
  verificationCode: string[] = Array(6).fill(''); // Use this property for OTP inputs
  resendOtpCountdown: number = 120; // Timer set to 2 minutes (120 seconds)
  timer: any; // Variable to hold the timer
  isLoading = false; // Add this flag for loader

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private apiService: ApiService, // Inject ApiService
    private snackBar: MatSnackBar // Inject MatSnackBar for notifications
  ) {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      new_password: ['', [Validators.required, Validators.minLength(8)]],
    });

    // Add custom validation to the new_password field
    this.forgotPasswordForm.get('new_password')?.setValidators([
      Validators.required,
      Validators.minLength(8),
      this.passwordValidator,
    ]);
  }

  ngOnInit(): void {
    // console.log('OTP Verified:', this.otpVerified);
    // console.log('OTP Sent:', this.otpSent);
  }

  onKeyUp(event: KeyboardEvent, index: number): void {
    const input = event.target as HTMLInputElement;
    const key = event.key;

    if (parseInt(input.value) >= 0 && parseInt(input.value) <= 9) {
      const nextInput = document.getElementById(`input${index + 2}`);
      if (nextInput) {
        nextInput.focus();
      }
    } else {
      input.value = '';
    }

    if (key === 'Backspace' || key === 'Delete') {
      const prevInput = document.getElementById(`input${index}`);
      if (prevInput && index > 0) {
        prevInput.focus();
      }
    }
  }

  goBack(): void {
    if (this.otpVerified) {
      this.otpVerified = false;
      this.router.navigate(['/login']);
    } else if (this.otpSent) {
      this.otpSent = false;
      // Optionally, stay on the current page or navigate to a different page
    } else {
      this.router.navigate(['/login']);
    }
  }

  handleSendOtp(): void {
    const email = this.forgotPasswordForm.get('email')?.value;
    if (email) {
      this.isLoading = true; // Start loading
  
      // Directly call the API service without delay
      this.apiService.sendOtp(email).subscribe({
        next: (response) => {
          this.isLoading = false; // Stop loading
          this.otpSent = true;
          this.startResendOtpTimer(); // Start the timer when OTP is sent
          this.successMessage = response.message;
          this.errorMessage = null;
          this.openSnackBar(response.message, 'custom-snackbar-success');
        },
        error: (error) => {
          this.isLoading = false; // Stop loading in case of error
          const errorMessage = error.error.detail || 'Failed to send OTP. Please try again.';
          this.successMessage = null;
          this.openSnackBar(errorMessage, 'custom-snackbar-error');
        },
      });
    } else {
      this.errorMessage = 'Please enter a valid email address.';
      this.successMessage = null;
      this.openSnackBar(this.errorMessage, 'custom-snackbar-error');
    }
  }  

  startResendOtpTimer(): void {
    this.resendOtpCountdown = 120; // Reset timer to 2 minutes
    this.timer = setInterval(() => {
      if (this.resendOtpCountdown > 0) {
        this.resendOtpCountdown--;
      } else {
        clearInterval(this.timer);
      }
    }, 1000);
  }

  handleVerifyOtp(): void {
    const email = this.forgotPasswordForm.get('email')?.value;
    const otp = this.verificationCode.join('');
    // console.log('Email:', email);
    // console.log('OTP:', otp);

    if (otp.length === 6 && email) {
      this.apiService.verifyOtp(email, otp).subscribe({
        next: (response) => {
          this.successMessage = response.message;
          this.errorMessage = null;
          this.openSnackBar('Verified successfully', 'custom-snackbar-success');
          this.otpVerified = true;
          this.resetPassword = true;
          // console.log('OTP Verified:', this.otpVerified);
        },
        error: (error) => {
          const errorMessage =  error.error.detail || 'Invalid OTP. Please try again.';
          this.successMessage = null;
          this.openSnackBar(errorMessage, 'custom-snackbar-error');
        },
      });
    } else {
      (error: { error: { detail: string; }; }) => {
      this.errorMessage = error.error.detail || 'Invalid OTP or email. Please check and try again.';
      this.successMessage = null;
      this.openSnackBar(this.errorMessage, 'custom-snackbar-error');
    }
    }
  }

  handleResetPassword(): void {
    const email = this.forgotPasswordForm.get('email')?.value;
    const otp = this.verificationCode.join('');
    const new_password = this.forgotPasswordForm.get('new_password')?.value;
  
    if (this.forgotPasswordForm.get('new_password')?.invalid) {
      const newPasswordControl = this.forgotPasswordForm.get('new_password');
      const errorMessages: string[] = [];
  
      if (newPasswordControl?.errors) {
        if (newPasswordControl.errors['required']) {
          errorMessages.push('New password is required.');
        }
        if (newPasswordControl.errors['minlength']) {
          errorMessages.push('New password must be at least 8 characters long');
        }
        if (newPasswordControl.errors['lowercase']) {
          errorMessages.push('contain at least one lowercase letter');
        }
        if (newPasswordControl.errors['uppercase']) {
          errorMessages.push('contain at least one uppercase letter');
        }
        if (newPasswordControl.errors['digit']) {
          errorMessages.push('contain at least one digit');
        }
        if (newPasswordControl.errors['special']) {
          errorMessages.push('contain at least one special symbol');
        }
      }
  
      if (errorMessages.length > 0) {
        const combinedErrorMessage = errorMessages.join(', ');
        this.openSnackBar(combinedErrorMessage, 'custom-snackbar-error');
      }
      return;
    }
  
    if (new_password && new_password.length >= 8 && otp.length === 6 && email) {
      this.apiService.resetPassword(email, otp, new_password).subscribe({
        next: (response) => {
          this.successMessage = response.message;
          this.errorMessage = null;
          this.openSnackBar(response.message, 'custom-snackbar-success');
          this.router.navigate(['/login']);
        },
        error: (error) => {
          const errorMessage = error.error.detail || 'Failed to reset password. Please try again.';
          this.successMessage = null;
          this.openSnackBar(errorMessage, 'custom-snackbar-error');
        },
      });
    } else {
      const errorMessage = 'Invalid input. Please check your information and try again.';
      this.successMessage = null;
      this.openSnackBar(errorMessage, 'custom-snackbar-error');
    }
  }
  
  

  passwordValidator(control: FormGroup): { [key: string]: boolean } | null {
    const password = control.value || '';
    const errors: { [key: string]: boolean } = {};
  
    if (!password) {
      errors['required'] = true;
    }
    if (password.length < 8) {
      errors['minlength'] = true;
    }
    if (!/[a-z]/.test(password)) {
      errors['lowercase'] = true;
    }
    if (!/[A-Z]/.test(password)) {
      errors['uppercase'] = true;
    }
    if (!/\d/.test(password)) {
      errors['digit'] = true;
    }
    if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) {
      errors['special'] = true;
    }
  
    // Return null if no errors, or the errors object if there are validation failures
    return Object.keys(errors).length > 0 ? errors : null;
  }
  

  openSnackBar(message: string, panelClass: string) {
    const config = {
      duration: 3000,
      panelClass: [panelClass],
    };
    this.snackBar.open(message, 'Close', config);
  }
}
