<body class="dark:bg-white">
    <app-above-header></app-above-header>
    <app-header></app-header>
  
<!-- Main Content Section -->
<main class="flex flex-col">

  <!-- Page Title -->
  <h1 class="text-3xl sm:text-4xl lg:text-5xl 2xl:text-custom-font-65 2xl:leading-custom-line-73 font-normal text-custom-gray text-center mt-64">
    <span class="font-times mr-3 lg:mr-4">Our</span>
    <span class="font-times italic mr-3 lg:mr-4">Terms</span>
    <span class="font-times block sm:inline">And Conditions</span>
  </h1>
<div class="px-10 lg:px-20 2xl:px-36 ml-5">
  <!-- Subtitle -->
  <h1 class="font-poppins text-sm xl:text-lg 3xl:text-xl font-bold text-custom-gray flex mt-8 tracking-[2px] 3xl:tracking-[4px]">
    BY SUBSCRIBING TO OUR FRESH JUICE DELIVERY SERVICE, YOU AGREE TO THE FOLLOWING TERMS AND CONDITIONS:
  </h1>

  <!-- Terms Content -->
  <section class="max-w-6xl w-full text-left text-[#2F2C2B] mt-28 space-y-6">
      <!-- Subscription Agreement -->
      <h2 class="section-title">SUBSCRIPTION AGREEMENT</h2>
      <ol class="ordered-list">
          <li class="max-w-[95ch] break-words">
              By placing an order, you agree to receive regular deliveries of our selected juices based on your subscription plan.
          </li>
          <li class="max-w-[90ch] break-words">
            Subscription options include weekly, bi-weekly, and monthly deliveries. You can modify or cancel your
            subscription at any time, subject to our cancellation policy.
          </li>                   
      </ol>

      <!-- Payment -->
      <h2 class="section-title">PAYMENT</h2>
      <ol class="ordered-list">
        <li class="max-w-[95ch] break-words">
          Payments are processed automatically according to the subscription cycle selected. We accept major credit and
          debit cards.
        </li>        
        <li>
          Prices are subject to change, but you will be notified in advance of any adjustments that affect your subscription.
        </li>
    </ol>

    <!-- Delivery -->
    <h2 class="section-title">DELIVERY</h2>
    <ol class="ordered-list">
      <li class="max-w-[95ch] break-words">
        We deliver to specified areas. Please ensure your delivery address is accurate and accessible. Nirdhara is not responsible for delivery failures due to incorrect or incomplete addresses.
      </li>        
      <li>
        If you are unavailable to receive the delivery, please provide instructions on where to leave the package securely.
      </li>
    </ol>

  <!-- PRODUCT FRESHNESS AND QUALITY -->
  <h2 class="section-title">PRODUCT FRESHNESS AND QUALITY</h2>
  <ol class="ordered-list">
    <li class="max-w-[95ch] break-words">
      We guarantee the freshness and quality of our juices. If you receive a damaged or substandard product, please contact us within 24 hours for a replacement or refund.
    </li>        
  </ol>

    <!-- 5MODIFICATIONS AND CANCELLATIONS -->
    <h2 class="section-title">5MODIFICATIONS AND CANCELLATIONS</h2>
    <ol class="ordered-list">
      <li class="max-w-[92ch] break-words">
        You may modify your subscription or cancel at any time through your account settings. Changes must be made at least 48 hours before your next scheduled delivery.     
     </li>        
      <li>
        Cancellations made after the cutoff time may be subject to a fee.
      </li>
    </ol>

    <!-- REFUNDS AND RETURNS -->
    <h2 class="section-title">REFUNDS AND RETURNS</h2>
    <ol class="ordered-list">
      <li class="max-w-[92ch] break-words">
        Due to the perishable nature of our products, refunds and returns are handled on a case-by-case basis. Please contact our customer service team for assistance. 
      </li>
    </ol>

    <!-- PRIVACY POLICY -->
    <h2 class="section-title">PRIVACY POLICY</h2>
    <ol class="ordered-list">
      <li class="max-w-[92ch] break-words">
        We value your privacy and are committed to protecting your personal information.     
      </li>        
      <li>
        Please review our Privacy Policy for details on how we collect, use, and safeguard your data.
      </li>
    </ol>

    <!-- CHANGES TO TERMS -->
    <h2 class="section-title">CHANGES TO TERMS</h2>
    <ol class="ordered-list">
      <li class="max-w-[92ch] break-words">
        Nirdhara reserves the right to modify these terms at any time. Any changes will be communicated to you via email or through our website. 
      </li>
    </ol>

  </section>
</div>
</main>
<h1 class="text-3xl sm:text-4xl 2xl:text-6xl 2xl:leading-custom-line-73 font-normal text-custom-gray text-center mt-40 p-2 md:p-0">
  <span class="font-times mr-3 lg:mr-4">Thank you for choosing</span>
  <span class="font-times italic mr-3 lg:mr-4">Nirdhara.</span><br/>
  <span class="font-times block sm:inline mr-3 lg:mr-4">We look forward to serving you with</span>
  <span class="font-times italic mr-3 lg:mr-4 text-nowrap">the best fresh juice</span><br/>
  <span class="font-times block sm:inline">delivery service available.</span>
</h1>
<div class="h-40 lg:h-60">

</div>
</body>
<app-footer></app-footer>