<!-- product.component.html -->
<body class="bg-white min-h-screen">
  <app-sidebar [activeTab]="activeTab"></app-sidebar>
  
    <div class="lg:ml-64">
        <div class="p-4">

<!-- Main container -->
  <div class="flex flex-col md:flex-row items-center justify-between w-full">
    
    <!-- Heading and Add button for screens smaller than md -->
    <div class="flex items-center justify-between w-full md:w-1/3 mb-4 md:mb-0">
      <h1 class="text-xl lg:text-2xl 2xl:text-4xl font-bold font-times text-custom-gray">All Product List</h1>
      <button
      (click)="togglePopup()" 
      class="bg-custom-gray hover:bg-gray-900 text-custom-text-color-button font-normal lg:font-medium py-1 2xl:py-2 px-2 2xl:px-4 font-poppins text-sm lg:text-lg tracking-widest w-40 lg:w-60 flex items-center justify-center md:hidden">
        <i class="fa-solid fa-plus mr-2"></i> Add Product
      </button>
    </div>
    
    <!-- Search bar -->
    <div class="relative w-full md:w-1/3 mx-0 md:mx-4 mb-2 md:mb-0">
      <i class="fa-solid fa-magnifying-glass absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400"></i>
      <input 
        type="text" 
        placeholder="Search Products..." 
        [(ngModel)]="searchQuery" 
        (input)="filterItems()" 
        class="bg-white pl-10 pr-4 py-1 lg:py-2 w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-custom-gold focus:border-transparent transition duration-300 ease-in-out"
      />
    </div>
    
    <!-- Add Product button for screens larger than md -->
    <div class="w-full md:w-1/3 flex items-center justify-center md:justify-end">
      <button 
      (click)="togglePopup()"
      class="bg-custom-gray hover:bg-gray-900 text-custom-text-color-button font-normal lg:font-medium py-1 2xl:py-2 px-2 2xl:px-4 font-poppins text-sm lg:text-lg tracking-widest w-40 lg:w-60 items-center justify-center hidden md:flex">
        <i class="fa-solid fa-plus mr-2"></i> Add Product
      </button>
    </div>
    
  </div>
          
          
                 <!-- Table container -->
        <div class="overflow-scroll max-h-[660px] sm:max-h-[700px]">
          <table class="mt-5 w-full min-w-max table-auto text-left">
            <thead class="bg-gray-50 sticky top-0 h-fit">
              <tr>
                <th class="cursor-pointer border-y border-gray-200 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                  <p class="antialiased font-sans text-sm text-custom-gray flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                    Product Name
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="h-4 w-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                    </svg>
                  </p>
                </th>
                <th class="cursor-pointer border-y border-gray-200 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                  <p class="antialiased font-sans text-sm text-custom-gray flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                    Quantity
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="h-4 w-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                    </svg>
                  </p>
                </th>
                <th class="cursor-pointer border-y border-gray-200 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                  <p class="antialiased font-sans text-sm text-custom-gray flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                    Price
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="h-4 w-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                    </svg>
                  </p>
                </th>
                <th class="cursor-pointer border-y border-gray-200 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                  <p class="antialiased font-sans text-sm text-custom-gray flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                    Actions
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="h-4 w-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                    </svg>
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- Loop through the products and display them -->
              <tr
              class="font-poppins text-sm leading-normal text-custom-gray font-normal hover:bg-gray-100 transition-colors"
               *ngFor="let product of filteredItems">
                <td class="p-4 border-b border-blue-gray-50">{{ product.product_name }}</td>
                <td class="p-4 border-b border-blue-gray-50">{{ product.quantity }} ML</td>
                <td class="p-4 border-b border-blue-gray-50">INR {{ product.price }}</td>
                <td class="p-4 border-b border-blue-gray-50">
                  <i class="fa-solid fa-pen-to-square mr-5 text-lg" (click)="editProduct(product)"></i>
                  <i class="fa-solid fa-trash text-lg" (click)="deleteProduct(product.product_master_id)"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
<!-- Product Popup Component -->
<app-product-popup 
  *ngIf="showPopup" 
  [product]="selectedProduct" 
  [editMode]="editMode" 
  (productUpdated)="handleProductUpdated($event)" 
  (closePopupEvent)="closePopup()">
</app-product-popup>

  </body>
  