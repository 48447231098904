import { Component } from '@angular/core';
import { ApiService, CartResponse } from '../api.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.css'
})
export class CartComponent {

  cartItems: CartResponse[] = [];
  selectedItems: CartResponse[] = [];

  subtotal: number = 0;
  selectAll: boolean = false;
  isEmpty: boolean = true;  // Added to track if the cart is empty
  showPopup: boolean = false;  // Controls the visibility of the popup
  minDate: string = ''; // Minimum date for the input
  cartCount: number = 0;


  toggleSelectAll(): void {
    this.cartItems.forEach(item => item.selected = this.selectAll);
    this.calculateSubtotal();
  }

  setMinDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    this.minDate = `${year}-${month}-${day}T00:00`; // Set the minimum date for the input
  }

  constructor(
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private router: Router,
  ){}

  ngOnInit(): void {
    this.loadCart();
   
    this.setMinDate(); // Set the minimum date on initialization

  }
  loadCart(): void {
    this.apiService.getUserCart().subscribe(
      (data) => {
        // Initialize the selected property for each item
        this.cartItems = data.map(item => ({ ...item, selected: false }));
        this.isEmpty = this.cartItems.length === 0;  // Check if the cart is empty

        this.cartCount = this.cartItems.length;
  
        this.calculateSubtotal();
        this.cartCounting(); // Call cartCounting to log the number of cart items
        console.log("inside the loadCart", this.cartCounting());
      },
      (error) => {
        // Handle error appropriately
        console.error('Failed to load cart items', error);
        // Optionally, you could show a notification to the user here
      }
    );
  }
  
  cartCounting(): number {
    console.log(`Number of items in the cart: ${this.cartCount}`);
    return this.cartCount;
  }

  calculateSubtotal(): void {
    this.subtotal = this.cartItems
      .filter(item => item.selected)  // Filter selected items
      .reduce((sum, item) => sum + (item.price), 0);  // Calculate subtotal based on selected items
  }

  get selectedItemsCount(): number {
    return this.cartItems.filter(item => item.selected).length;
  }

  deleteItem(cartId: string): void {
    this.apiService.deleteCart(cartId).subscribe(
      () => {
        this.openSnackBar('Cart item deleted successfully!', 'custom-snackbar');
        this.loadCart(); // Reload the cart items
      },
      (error) => {
        // console.error('Failed to delete cart item', error);
        this.openSnackBar('Failed to delete cart item.',  'custom-snackbar');
      }
    );
  }

  redirectToShop(): void {
    // Replace with your actual shop route
    window.location.href = '/subscribe';
  }
  
  proceedToCheckout(): void {
    this.selectedItems = this.cartItems.filter(item => item.selected);
    if (this.selectedItems.length > 0) {
      this.openPopup(); // Open popup for date selection
    } else {
      this.openSnackBar('Please select at least one item to proceed.', 'custom-snackbar');
    }
  }

  openSnackBar(message: string, panelClass: string) {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.panelClass = [panelClass];
    this.snackBar.open(message, 'Close', config);
  }
  openPopup(): void {
    this.showPopup = true;
    this.selectedItems = this.cartItems.filter(item => item.selected);
  }

  confirmCheckout(): void {
    if (this.selectedItems.some(item => !item.start_date)) {
      this.openSnackBar('Please select a start date for all items.', 'custom-snackbar');
      return;
    }

    // Store selected items and their start dates in local storage
    localStorage.setItem('selectedCartItems', JSON.stringify(this.selectedItems));
    this.showPopup = false; // Close popup

    // Redirect to checkout page
    this.router.navigate(['/checkout']);
  }
}