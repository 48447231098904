import { Component, OnInit, AfterViewInit, ViewChild, ViewContainerRef, ComponentRef, ComponentFactoryResolver } from '@angular/core';
import { Router } from '@angular/router';
import { faShoppingCart, faUser, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ApiService, CartResponse } from '../api.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { CartComponent } from '../cart/cart.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  @ViewChild('cartContainer', { read: ViewContainerRef }) cartContainer!: ViewContainerRef;
  private cartComponentRef!: ComponentRef<CartComponent>;
  cartItems: CartResponse[] = [];
  selectedItems: CartResponse[] = [];

  subtotal: number = 0;
  selectAll: boolean = false;
  isEmpty: boolean = true;  // Added to track if the cart is empty
  showPopup: boolean = false;  // Controls the visibility of the popup
  minDate: string = ''; // Minimum date for the input
  cartCount: number = 0;
  showLinks: boolean = true;
  isLoggedIn: boolean = false;
  cartItemCount: number = 0;

  faShoppingCart = faShoppingCart;
  faUser = faUser;
  faBars = faBars;
  faTimes = faTimes;
  menuOpen = false;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private componentFactoryResolver: ComponentFactoryResolver


  ) {}

  ngOnInit() {
    this.loadCart();

    // Subscribe to route changes
    this.router.events.subscribe(() => {
      const currentRoute = this.router.url;
      // Hide links on login and register pages
      this.showLinks = !currentRoute.includes('/sidebar') && !currentRoute.includes('/admin-dashboard')
      && !currentRoute.includes('/product');

      // Check if the user is logged in
    this.isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    });

    
  }
  loadCart(): void {
    this.apiService.getUserCart().subscribe(
      (data) => {
        // Initialize the selected property for each item
        this.cartItems = data.map(item => ({ ...item, selected: false }));
        this.isEmpty = this.cartItems.length === 0;  // Check if the cart is empty
        this.cartCount = this.cartItems.length;

       
    
        this.cartCounting(); // Call cartCounting to log the number of cart items
      },
      (error) => {
        // console.error('Failed to load cart items', error);
        // this.openSnackBar('Failed to load cart items.',  'custom-snackbar');
      }
    );
  }

  cartCounting(): number {
    console.log(`Number of items in the cart: ${this.cartCount}`);
    return this.cartCount;
  }
  

  ngAfterViewInit() {
    this.loadCartComponent();
  }
  loadCartComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(CartComponent);
    this.cartComponentRef = this.cartContainer.createComponent(componentFactory);
    this.cartComponentRef.instance.cartCounting(); // Call the method on the CartComponent instance if needed
  }


  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  logout() {
    this.apiService.logout().subscribe({
      next: () => {
        this.router.navigate(['/login']);
        this.openSnackBar('Logout successful!', 'custom-snackbar');
      },
      error: (error) => {
        // const errorMessage = error.error.detail || 'An error occurred during logout. Please try again!';
        // this.openSnackBar(errorMessage, 'custom-snackbar');
        localStorage.removeItem('session_user_id');
        localStorage.removeItem('session_role_id');
        localStorage.setItem('isLoggedIn', 'false'); // Update login state

      this.router.navigate(['/login']); // Navigate to login page
      }
    });
  }

  navigateToLogin() {
    this.router.navigate(['/login']);
  }

  openSnackBar(message: string, panelClass: string) {
    const config = new MatSnackBarConfig();
    config.duration = 2000;
    config.panelClass = [panelClass];
    this.snackBar.open(message, 'Close', config);
  }
}
