import { Component } from '@angular/core';

@Component({
  selector: 'app-above-header-no-text',
  templateUrl: './above-header-no-text.component.html',
  styleUrl: './above-header-no-text.component.css'
})
export class AboveHeaderNoTextComponent {

}
