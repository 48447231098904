<div class="mt-5 lg:mt-28 min-h-full 3xl:min-h-screen">
    <h1 class="text-3xl sm:text-5xl lg:text-6xl 3xl:text-7xl pl-4 md:pl-12 lg:pl-20 mb-10 text-custom-gray ">
      <span class="font-times mr-4">Our</span>
      <span class="font-times italic mr-4">Bestsellers</span>
    </h1>
    <div class="mx-auto py-5 px-4 sm:pl-8">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 pt-8 pb-8">
    
      <div class="relative text-center flex flex-col items-center justify-between sm:px-10">
        <!-- Pseudo-element for the border -->
        <div class="absolute top-0 right-0 h-full md:border-r border-[#707070] transform translate-x-2 md:translate-x-3"></div>
      
        <div class=" h-full w-full flex justify-center items-center">
          <!-- Image goes here -->
           <img src="../../assets/images/valencia.png"/>
        </div>
        <h1 class="text-xl 2xl:text-3xl text-custom-gray mt-8">
          <span class="font-times">VALENCIA </span> <span class="font-times">ORANGE</span> <br /> <span class="font-times">JUICE</span>
        </h1>
        <p class="text-sm 3xl:text-xl font-poppins font-semibold mt-4 lg:mt-8 text-custom-gray tracking-[3px]">
          FROM INR 100
        </p>
      </div>
  
      <div class="text-center lg:border-r border-[#707070] flex flex-col items-center justify-between sm:px-10">
        <div class=" h-full w-full flex justify-center items-center">
          <!-- Image goes here -->
           <img src="../../assets/images/valencia1.png"/>
        </div>
        <h1 class="text-xl 2xl:text-3xl text-custom-gray mt-8">
          <span class="font-times">VALENCIA </span> <span class="font-times">ORANGE</span> <br /> <span class="font-times">JUICE</span>
        </h1>
        <p class="text-sm 3xl:text-xl font-poppins font-semibold mt-4 lg:mt-8 text-custom-gray tracking-[3px]">
          FROM INR 100
        </p>
      </div>
  
      <div class="relative text-center flex flex-col items-center justify-between sm:px-10">
        <!-- Pseudo-element for the border -->
        <div class="absolute top-0 right-0 h-full md:border-r border-[#707070] transform translate-x-2 md:translate-x-3"></div>
      
        <div class=" h-full w-full flex justify-center items-center">
          <!-- Image goes here -->
           <img src="../../assets/images/valencia1.png"/>
        </div>
        <h1 class="text-xl 2xl:text-3xl text-custom-gray mt-8">
          <span class="font-times">VALENCIA </span> <span class="font-times">ORANGE</span> <br /> <span class="font-times">JUICE</span>
        </h1>
        <p class="text-sm 3xl:text-xl font-poppins font-semibold mt-4 lg:mt-8 text-custom-gray tracking-[3px]">
          FROM INR 100
        </p>
      </div>
  
      <div class="text-center flex flex-col items-center justify-between sm:px-10">
        <div class=" h-full w-full flex justify-center items-center">
          <!-- Image goes here -->
           <img src="../../assets/images/valencia1.png"/>
        </div>
        <h1 class="text-xl 2xl:text-3xl text-custom-gray mt-8">
          <span class="font-times">VALENCIA </span> <span class="font-times">ORANGE</span> <br /> <span class="font-times">JUICE</span>
        </h1>
        <p class="text-sm 3xl:text-xl font-poppins font-semibold mt-4 lg:mt-8 text-custom-gray tracking-[3px]">
          FROM INR 100
        </p>
      </div>
    </div>
  </div>
    <h1 class="text-2xl sm:text-3xl lg:text-5xl 2xl:text-6xl pl-4 sm:pl-12 lg:pl-20 font-medium text-custom-gray  flex mt-2 2xl:mt-14 mb-12">
      <span class="font-times mr-4 border-b underline">See all our</span>
      <span class="font-times italic mr-4 border-b underline">juice</span>
      <span class="font-times border-b underline">options.</span>
    </h1>
  </div>