<html>
<head>
    <title>Dashboard</title>
</head>
<body class="bg-white min-h-screen">
    <app-sidebar></app-sidebar>

    <!-- Main Content -->
    <main class="flex-1 p-6 lg:ml-64 h-full font-poppins">
        <!-- Cards Section -->
        <div class="mb-6 lg:mb-10">
            <h2 class="text-lg font-medium text-gray-600 mb-6">Activity Overview</h2>
            <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6">
                <!-- Example Cards -->
                <div class="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transform transition-transform duration-300 hover:scale-105 relative border-t-8 border-custom-teal flex items-center justify-between">
                    <div>
                        <h2 class="text-lg font-medium text-nowrap text-gray-600">Total Customer</h2>
                        <p class="mt-2 text-xl lg:text-2xl 2xl:text-3xl font-bold text-custom-gray font-times">200</p>
                    </div>
                    <div class="flex justify-end items-center">
                        <div class="bg-gradient-to-r from-yellow-400 to-yellow-500 rounded-full p-4">
                            <i class="fas fa-users text-white text-4xl"></i>
                        </div>
                    </div>
                </div>

                <div class="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transform transition-transform duration-300 hover:scale-105 relative border-t-8 border-custom-teal flex items-center justify-between">
                    <div>
                        <h2 class="text-lg font-medium text-gray-600 text-nowrap">Total Active / Inactive Subscription</h2>
                        <p class="mt-2 text-xl lg:text-2xl 2xl:text-3xl font-bold text-custom-gray font-times">50 / 5</p>
                    </div>
                    <div class="flex justify-end items-center">
                        <div class="bg-gradient-to-r from-green-400 to-green-500 rounded-full p-4">
                            <i class="fas fa-calendar-days text-white text-4xl"></i>
                        </div>
                    </div>
                </div>

                <div class="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transform transition-transform duration-300 hover:scale-105 relative border-t-8 border-custom-teal flex items-center justify-between">
                    <div>
                        <h2 class="text-lg font-medium text-gray-600">Delivered / Pending</h2>
                        <p class="mt-2 text-xl lg:text-2xl 2xl:text-3xl font-bold text-custom-gray font-times">60 / 20</p>
                    </div>
                    <div class="flex justify-end items-center">
                        <div class="bg-gradient-to-r from-blue-400 to-blue-500 rounded-full p-4">
                            <i class="fas fa-truck text-white text-4xl"></i>
                        </div>
                    </div>
                </div>

                <div class="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transform transition-transform duration-300 hover:scale-105 relative border-t-8 border-custom-teal flex items-center justify-between">
                    <div>
                        <h2 class="text-lg font-medium text-gray-600">Total Sales</h2>
                        <p class="mt-2 text-xl lg:text-2xl 2xl:text-3xl font-bold text-custom-gray font-times">INR 5000</p>
                    </div>
                    <div class="flex justify-end items-center">
                        <div class="bg-gradient-to-r from-red-400 to-red-500 rounded-full p-4">
                            <i class="fas fa-clock text-white text-4xl"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

       <!-- Chart Section -->
<div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
  <!-- Container for Sales vs Orders -->
  <div class="bg-white p-6 rounded-lg shadow-md w-full">
    <h2 class="text-lg font-medium text-gray-600 mb-8">Sales vs Orders</h2>
    <div class="relative" style="height: 0; padding-bottom: 50%;">
      <canvas #salesOrdersChart class="absolute inset-0 w-full h-full"></canvas>
    </div>
  </div>

  <!-- Container for Subscription Types -->
  <div class="bg-white p-6 rounded-lg shadow-md w-full">
    <h2 class="text-lg font-medium text-gray-600 mb-8">Subscription Types</h2>
    <div class="relative" style="height: 0; padding-bottom: 50%;">
      <canvas #subscriptionChart class="absolute inset-0 w-full h-full"></canvas>
    </div>
  </div>
</div>

        
    </main>
</body>
</html>
