<!-- product-popup.component.html -->
<div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 font-poppins">
    <div class="bg-white rounded-lg shadow-lg w-[95%] max-w-sm p-3 sm:p-6">
      <button class="text-custom-gray hover:text-black text-2xl font-bold float-right focus:outline-none" (click)="closePopup()">×</button>
      <h2 class="font-medium text-custom-gray text-2xl mb-3 border-b-2 border-custom-teal pb-2 sm:pb-4">{{ editMode ? 'Edit Product' : 'Add Product' }}</h2>

      <form [formGroup]="productForm" (ngSubmit)="onSubmit()" class="space-y-4">
        <!-- Product Name -->
        <div class="mb-2 sm:mb-6 relative">
          <label for="product_name" class="block text-gray-700 mb-1 ml-6">Product Name</label>
          <div class="flex items-center">
            <i class="fas fa-wine-bottle text-xl text-custom-gray mr-2"></i>
            <input
              id="product_name"
              formControlName="product_name"
              class="flex-1 min-w-0 w-full text-sm rounded-md border bg-white py-1 px-2 sm:py-2 sm:px-4 text-gray-600 shadow-sm outline-none focus:border-custom-teal focus:shadow-lg transition duration-300 ease-in-out"
            />
          </div>
        </div>

        <!-- Quantity -->
        <div class="mb-2 sm:mb-6 relative">
          <label for="quantity" class="block text-gray-700 mb-1 ml-6">Quantity</label>
          <div class="flex items-center">
            <i class="fas fa-sort-amount-up-alt text-xl text-custom-gray mr-2"></i>
            <input
              id="quantity"
              formControlName="quantity"
              required
              class="flex-1 min-w-0 w-full text-sm rounded-md border bg-white py-1 px-2 sm:py-2 sm:px-4 text-gray-600 shadow-sm outline-none focus:border-custom-teal focus:shadow-lg transition duration-300 ease-in-out"
            />
          </div>
        </div>

        <!-- Price -->
        <div class="mb-2 sm:mb-6 relative">
          <label for="price" class="block text-gray-700 mb-1 ml-6">Price</label>
          <div class="flex items-center">
            <i class="fas fa-rupee-sign text-xl text-custom-gray mr-3"></i>
            <input
              id="price"
              formControlName="price"
              type="text"
              required
              class="flex-1 min-w-0 w-full text-sm rounded-md border bg-white py-1 px-2 sm:py-2 sm:px-4 text-gray-600 shadow-sm outline-none focus:border-custom-teal focus:shadow-lg transition duration-300 ease-in-out"
            />
          </div>
        </div>

        <!-- Submit Button -->
        <div class="flex justify-center mt-2 sm:mt-6">
          <button
            type="submit"
            class="bg-custom-gray hover:bg-gray-900 text-custom-text-color-button font-normal lg:font-medium py-1 lg:py-2 px-2 lg:px-4 font-times text-sm lg:text-lg tracking-widest w-fit"
          >
            {{ editMode ? 'Update' : 'Submit' }}
          </button>
        </div>
      </form>
    </div>
</div>

  
