<body class="bg-white min-h-screen">
    <app-sidebar [activeTab]="activeTab"></app-sidebar>
    
  
    <div class="lg:ml-64">
        <div class="p-4">

<!-- Main container -->
  <div class="flex flex-col md:flex-row items-center justify-between w-full">
    
    <!-- Heading and Add button for screens smaller than md -->
    <div class="flex items-center justify-between w-full md:w-1/3 mb-4 md:mb-0">
      <h1 class="text-xl lg:text-2xl 2xl:text-4xl font-bold font-times text-custom-gray">All Customer List</h1>
      
    </div>
    
    <!-- Search bar -->
    <div class="relative w-full md:w-1/3 mx-0 md:mx-4 mb-2 md:mb-0">
      <i class="fa-solid fa-magnifying-glass absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400"></i>
      <input 
        type="text" 
        placeholder="Search Customers..." 
        class="bg-white pl-10 pr-4 py-1 lg:py-2 w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-custom-gold focus:border-transparent transition duration-300 ease-in-out"
        [(ngModel)]="searchTerm" (input)="filterUsers()"
        />
    </div>

    <div class="relative w-full md:w-1/3 mx-0 md:mx-4 mb-2 md:mb-0"></div>

    </div>
    <!-- Table container -->
    <div class="overflow-scroll max-h-[660px] sm:max-h-[700px]">
        <table class="mt-5 w-full min-w-max table-auto text-left">
          <thead class="bg-gray-50 sticky top-0 h-fit">
            <tr>
              <th class="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                <p class="antialiased font-sans text-sm text-custom-gray flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                  Customer Name
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="h-4 w-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                  </svg>
                </p>
              </th>
              <th class="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                <p class="antialiased font-sans text-sm text-custom-gray flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                  Email
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="h-4 w-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                  </svg>
                </p>
              </th>
              <th class="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                <p class="antialiased font-sans text-sm text-custom-gray flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                  Mobile Number
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="h-4 w-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                  </svg>
                </p>
              </th>
              <th class="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                <p class="antialiased font-sans text-sm text-custom-gray flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                  Address
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="h-4 w-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                  </svg>
                </p>
              </th>
              <th class="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                <p class="antialiased font-sans text-sm text-custom-gray flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                  Status
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="h-4 w-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                  </svg>
                </p>
              </th>
              <th class="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                <p class="antialiased font-sans text-sm text-custom-gray flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                  Actions
                  
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- Loop through the products and display them -->
            <tr
            *ngFor="let user of filteredUserList"
            class="font-poppins text-sm leading-normal text-custom-gray font-normal hover:bg-gray-100 transition-colors"
             >
              <td class="p-4 border-b border-blue-gray-50">{{ getFullName(user) }}</td>
              <td class="p-4 border-b border-blue-gray-50">{{ user.email || '--' }}</td>
              <td class="p-4 border-b border-blue-gray-50">{{ user.mobile || '--' }}</td>
              <td class="p-4 border-b border-blue-gray-50">{{ getAddressDisplay(user) }}</td>
              <td class="p-4 border-b border-blue-gray-50">{{ user.is_active ? 'Active' : 'Inactive' }}</td>
              <td class="p-4 border-b border-blue-gray-50">
                <i class="fa-solid fa-trash text-lg cursor-pointer" (click)="deleteUser(user.user_id)"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>


    </div>
  </div>
</body>
