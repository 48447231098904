import { Component } from '@angular/core';

@Component({
  selector: 'app-juice-delivery',
  templateUrl: './juice-delivery.component.html',
  styleUrl: './juice-delivery.component.css'
})
export class JuiceDeliveryComponent {

}
