import { Component } from '@angular/core';

@Component({
  selector: 'app-juice-options',
  templateUrl: './juice-options.component.html',
  styleUrl: './juice-options.component.css'
})
export class JuiceOptionsComponent {

}
