import { Component } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {

  registerForm: FormGroup;

  formSubmitted = false;
  isLoading = false; // Add this flag for loader

  // Inject ApiService and Router
  constructor(    private fb: FormBuilder,
                  private apiService: ApiService, private router: Router,private snackBar: MatSnackBar // Inject MatSnackBar
  ) {
    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  onSubmit() {
    if (this.registerForm.invalid) {
      this.registerForm.markAllAsTouched();
      return;
    }
    this.isLoading = true; // Start loading
      this.apiService.createUser(this.registerForm.value).subscribe(
        (response) => {
          this.isLoading = false; // Stop loading
          this.openSnackBar('Check your email!', 'custom-snackbar');
          this.router.navigate(['/email-verification'], {
            state: { email: this.registerForm.value.email }
          });
        },
        (error) => {
          this.isLoading = false; // Stop loading
          const errorMessage = error.error.detail || 'Error during registration!';
          // console.error('Error during registration:', errorMessage);
          this.openSnackBar(errorMessage, 'custom-snackbar');
        }
      );
  }

  get password() {
    return this.registerForm.get('password');
  }

  validatePassword() {
    const password = this.password?.value || '';
    let valid = true;

    if (!password) {
      this.password?.setErrors({ required: true });
      valid = false;
    } else {
      // Check each condition and set the first encountered error
      if (password.length < 8) {
        this.password?.setErrors({ minlength: true });
        valid = false;
      } else if (!/[a-z]/.test(password)) {
        this.password?.setErrors({ lowercase: true });
        valid = false;
      } else if (!/[A-Z]/.test(password)) {
        this.password?.setErrors({ uppercase: true });
        valid = false;
      } else if (!/\d/.test(password)) {
        this.password?.setErrors({ digit: true });
        valid = false;
      } else if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) {
        this.password?.setErrors({ special: true });
        valid = false;
      } else {
        this.password?.setErrors(null);
      }
    }

    return valid;
  }

  openSnackBar(message: string, panelClass: string) {
    const config = new MatSnackBarConfig();
    config.duration = 2000;
    config.panelClass = [panelClass];
    this.snackBar.open(message, 'Close', config);
  }
}
