import { Component, Input } from '@angular/core'; // Import Input decorator
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {
  @Input() activeTab: string = 'Dashboard'; // Declare activeTab as an input property

  isMenuOpen = false;
  menu1Open = false;
  menu2Open = false;
  menu3Open = false;
  isMenu1Visible: any;
  searchQuery: any;
  isNavOpen: any;

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  showMenu1(flag: boolean) {
    if (flag) {
      this.menu1Open = !this.menu1Open;
    }
  }

  showMenu2(flag: boolean) {
    if (flag) {
      this.menu2Open = !this.menu2Open;
    }
  }

  showMenu3(flag: boolean) {
    this.menu3Open = !this.menu3Open;
  }

  setActiveTab(tabName: string) {
    this.activeTab = tabName;
  }

  isActiveTab(tabName: string): boolean {
    return this.activeTab === tabName;
  }

  constructor(private router: Router,    private apiService: ApiService,
    private snackBar: MatSnackBar,) {}

  ngOnInit() {}

  logout() {
    this.apiService.logout().subscribe({
      next: () => {
        this.router.navigate(['/login']);
        this.openSnackBar('Logout successful!', 'custom-snackbar');
      },
      error: (error) => {
        // const errorMessage = error.error.detail || 'An error occurred during logout. Please try again!';
        // this.openSnackBar(errorMessage, 'custom-snackbar');
        localStorage.removeItem('session_user_id');
        localStorage.removeItem('session_role_id');
        localStorage.setItem('isLoggedIn', 'false'); // Update login state

      this.router.navigate(['/login']); // Navigate to login page
      }
    });
  }

  openSnackBar(message: string, panelClass: string) {
    const config = new MatSnackBarConfig();
    config.duration = 2000;
    config.panelClass = [panelClass];
    this.snackBar.open(message, 'Close', config);
  }
  
}
