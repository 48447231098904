import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SubscriptionDataService } from './services/subscription-data.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CheckoutGuard implements CanActivate {

  constructor(
    private subscriptionDataService: SubscriptionDataService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const sessionUserId = localStorage.getItem('session_user_id');

    // First, check if the user is logged in
    if (!sessionUserId) {
      // Redirect to login if not logged in, preserving the return URL
      this.router.navigate(['/login'], { state: { returnUrl: state.url } });
      return false;
    }

    // If the user is logged in, check for subscription data or selected cart items
    return this.subscriptionDataService.getSubscriptionData().pipe(
      map((subscriptionData) => {
        const selectedCartItems = localStorage.getItem('selectedCartItems');

        if (subscriptionData || selectedCartItems) {
          return true; // Allow navigation if data is present
        } else {
          this.router.navigate(['/subscribe']); // Redirect to subscription page if no data
          return false; // Prevent navigation
        }
      })
    );
  }
}
