import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

// Define the interface for a step
export interface Step {
  id: number;
  title: string;
  description: string;
  position: {
    mt: string;  // margin-top
    ml: string;  // margin-left
    numberMt: string;  // margin-top for the number
    numberMl: string;  // margin-left for the number
    
  };
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent {
  constructor(private router: Router) {}

  ngOnInit() {
    // Scroll to the top on navigation
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  startSubscription(type: string) {
    this.router.navigate(['/subscription', type]);
  }
  
  steps: Step[] = [
    {
      id: 1,
      title: 'Choose your subscription plan',
      description: 'Craft a subscription as per your schedule. A daily juice delivery or a tailored schedule, our plans are flexible.',
      position: { mt: '15px', ml: '550px', numberMt: '30px', numberMl: '-5px' }
    },
    {
      id: 2,
      title: 'Select your juice',
      description: 'Dive into a world of fresh, flavorful options.',
      position: { mt: '184px', ml: '1030px', numberMt: '-160px', numberMl: '230px' }
    },
    // Add the rest of the steps here with their positions...
  ];
}
