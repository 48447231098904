import { Component } from '@angular/core';

@Component({
  selector: 'app-above-header',
  templateUrl: './above-header.component.html',
  styleUrl: './above-header.component.css'
})
export class AboveHeaderComponent {

}
