import { Component, OnInit } from '@angular/core';
import { ApiService, UserSubscription } from '../api.service'; // Your custom UserSubscription interface

@Component({
  selector: 'app-admin-subscription',
  templateUrl: './admin-subscription.component.html',
  styleUrls: ['./admin-subscription.component.css']
})
export class AdminSubscriptionComponent implements OnInit {
  activeTab: string = 'Subscription';
  subscriptions: UserSubscription[] = []; 

  filteredSubscriptions: UserSubscription[] = []; // Filtered subscriptions
  searchQuery: string = ''; // Variable to store the search term

  selectedAddress: any = null; // Property to hold selected address

  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.apiService.getSubscriptions().subscribe((data: UserSubscription[]) => {
      this.subscriptions = data;
      this.filteredSubscriptions = data; // Initially show all subscriptions
    });
  }

  filterSubscriptions() {
    if (this.searchQuery.trim()) {
      const lowerCaseQuery = this.searchQuery.toLowerCase();
      
      this.filteredSubscriptions = this.subscriptions.filter(subscription => {
        const idMatch = typeof subscription.subscription_id === 'string' && 
                        subscription.subscription_id.toLowerCase().includes(lowerCaseQuery);
        const emailMatch = typeof subscription.email === 'string' && 
                          subscription.email.toLowerCase().includes(lowerCaseQuery);
        const productMatch = typeof subscription.product.product_name === 'string' && 
                            subscription.product.product_name.toLowerCase().includes(lowerCaseQuery);
        const typeMatch = typeof subscription.subscription_type === 'string' && 
                          subscription.subscription_type.toLowerCase().includes(lowerCaseQuery);
        const addressMatch = Object.values(subscription.address).some(field =>
          typeof field === 'string' && field.toLowerCase().includes(lowerCaseQuery)
        );
        
        const statusMatch = (subscription.is_active ? 'active' : 'inactive').includes(lowerCaseQuery);
        
        // Convert the subscription's start_date to a string that matches the search format
        const dateMatch = new Date(subscription.start_date).toLocaleString('en-GB', {
          day: '2-digit', 
          month: 'short', 
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true // To match AM/PM formatting
        }).toLowerCase().includes(lowerCaseQuery);
  
        return idMatch || emailMatch || productMatch || typeMatch || addressMatch || statusMatch || dateMatch;
      });
    } else {
      this.filteredSubscriptions = [...this.subscriptions]; // Reset to all subscriptions if no search query
    }
  }
  
  

  // Method to display the address details popup
  showAddressPopup(address: any) {
    this.selectedAddress = address;
  }

  // Method to close the popup
  closePopup() {
    this.selectedAddress = null;
  }

  // Convert string to title case
  toTitleCase(str: string): string {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }
  
}
