import { Component, OnInit } from '@angular/core';
import { ApiService, UserWithAddress } from '../api.service'; // Ensure correct path
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {
  activeTab: string = 'Customer';
  userList: UserWithAddress[] = []; // To store fetched user list

  filteredUserList: UserWithAddress[] = []; // To store filtered user list
  searchTerm: string = ''; // To capture search input

  constructor(private apiService: ApiService, private snackBar: MatSnackBar ) {}

  ngOnInit(): void {
    this.fetchUserList();
  }

  // Method to fetch user list from the API
  fetchUserList() {
    this.apiService.getUserList().subscribe(
      (data: UserWithAddress[]) => {
        this.userList = data;
        this.filteredUserList = data; // Initially, show all users
      },
      (error) => {
        // console.error('Error fetching user list:', error);
        this.openSnackBar('Error fetching user list.', 'error-snackbar');
      }
    );
  }

  // Method to filter users based on the search term
  filterUsers() {
    const lowercasedTerm = this.searchTerm.toLowerCase();
  
    this.filteredUserList = this.userList.filter(user => {
      const nameMatch = this.getFullName(user).toLowerCase().includes(lowercasedTerm);
      const emailMatch = user.email && user.email.toLowerCase().includes(lowercasedTerm);
      const mobileMatch = user.mobile && user.mobile.toLowerCase().includes(lowercasedTerm);
      const addressMatch = this.getAddressDisplay(user).toLowerCase().includes(lowercasedTerm);
  
      // Assuming user.isActive or user.status indicates "active" or "inactive" status
      const statusMatch = (user.is_active ? 'active' : 'inactive').includes(lowercasedTerm);
  
      return nameMatch || emailMatch || mobileMatch || addressMatch || statusMatch;
    });
  }
  

  // Method to delete a user
  deleteUser(userId: string) {
    this.apiService.deleteUser(userId).subscribe(
      () => {
        // Remove the deleted user from the userList array
        this.userList = this.userList.filter(user => user.user_id !== userId);
        this.filteredUserList = this.filteredUserList.filter(user => user.user_id !== userId);
        // Show a success message in the snackbar
        this.openSnackBar('Customer deleted successfully.', 'success-snackbar');
      },
      (error) => {
        // console.error('Error deleting user:', error);
        // Show an error message in the snackbar
        this.openSnackBar('Error deleting user.', 'error-snackbar');
      }
    );
  }

  // Method to compute the display value for the full name
  getFullName(user: any): string {
    const firstName = user.address?.first_name || '--';
    const lastName = user.address?.last_name || '--';
    return (firstName !== '--' || lastName !== '--') ? `${firstName} ${lastName}` : '--';
  }

  // Method to compute the display value for the address
  getAddressDisplay(user: any): string {
    if (user.address) {
      const addressParts = [
        user.address.address,
        user.address.area,
        user.address.city,
        user.address.state,
        user.address.zip_code
      ].filter(part => part); // Filter out null or undefined values

      return addressParts.length > 0 ? addressParts.join(', ') : '--';
    }
    return '--';
  }

  openSnackBar(message: string, panelClass: string) {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.panelClass = [panelClass];
    this.snackBar.open(message, 'Close', config);
  }
}
