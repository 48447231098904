<div class="fixed inset-0 bg-[#909090] bg-opacity-75 z-50 flex justify-end">
  <div class="bg-white shadow-lg w-fit h-full max-w-screen-popup-xl  overflow-y-auto">
    <div class="flex justify-end mt-5 mr-16">
      <button (click)="close()" class="text-[#2F2C2B] font-poppins text-4xl md:text-5xl xl:text-6xl font-normal">
        X
      </button>
    </div>
    <div>
    <h1 class="text-2xl sm:text-3xl lg:text-4xl 2xl:text-custom-font-45 text-custom-gray mt-4 mb-5 pl-10 pr-10 md:pr-36 lg:pr-12 xl:pr-10 2xl:pr-48 3xl:pr-56">
      <span class="font-times">SUBSCRIPTION </span> 
      <span class="font-times italic">benefits</span>
    </h1>
    <!-- Use an hr or div for the line -->
    <hr class="border-t border-[#707070] mt-4 w-full" />
    
    <div class="mt-6 pb-4 md:pb-6 pl-10 pr-10 md:pr-36 lg:pr-12 xl:pr-32 2xl:pr-48 3xl:pr-56">
      <h3 class="text-xl text-custom-font-25 leading-custom-line-50 text-[#2F2C2B] font-poppins font-extrabold">CONVENIENT</h3>
      <p class="text-[#2F2C2B] text-lg 2xl:text-custom-font-22 max-w-sm md:max-w-[450px] xl:max-w-[420px] 2xl:max-w-lg leading-loose font-poppins font-normal">You don't have to go through the hassle of ordering daily, just choose a subscription plan and let us take care of the rest.</p>
    </div>
        <!-- Use an hr or div for the line -->
        <hr class="border-t border-[#707070] mt-4 w-full" />

    <div class="mt-6 pb-4 md:pb-6 pl-10 pr-10 md:pr-36 lg:pr-12 xl:pr-32 2xl:pr-48 3xl:pr-56">
      <h3 class="text-xl text-custom-font-25 mb-5 sm:mb-0 sm:leading-custom-line-73 text-[#2F2C2B] font-poppins font-extrabold">SOMETHING FOR EVERYONE</h3>
      <p class="text-[#2F2C2B] text-lg 2xl:text-custom-font-22 max-w-sm md:max-w-[450px] xl:max-w-[440px] 2xl:max-w-xl leading-loose font-poppins font-normal">We understand your requirements might differ, daily or monthly. Hence, we provide you with three subscription options. Choose the one that suits you the best!</p>
    </div>
        <!-- Use an hr or div for the line -->
        <hr class="border-t border-[#707070] mt-4 w-full" />

    <div class="mt-6 md:mt-8 pb-4 md:pb-6 pl-10 pr-10 md:pr-36 lg:pr-12 xl:pr-32 2xl:pr-48 3xl:pr-56">
      <h3 class="text-xl text-custom-font-25 leading-custom-line-73 text-[#2F2C2B] font-poppins font-extrabold">NO COMMITMENT</h3>
      <p class="text-[#2F2C2B] text-lg 2xl:text-custom-font-22 max-w-sm md:max-w-[450px] xl:max-w-[450px] 2xl:max-w-xl leading-loose font-poppins font-normal">Our flexible plans allow you to PAUSE or CANCEL your subscription plans through your account profile, anytime, anywhere!</p>
    </div>
  </div>
  </div>
</div>
