<body class="dark:bg-white">
<app-above-header></app-above-header>
<app-header></app-header>
<!-- New Section -->
<div class="px-5 xl:px-16 mt-16">
  <h1 class="text-3xl sm:text-4xl lg:text-5xl 2xl:text-custom-font-65 2xl:leading-custom-line-73 font-normal text-custom-gray  text-center">
  <span class="font-times mr-3 lg:mr-4">Our</span>
  <span class="font-times italic mr-3 lg:mr-4">subscription</span>
  <span class="font-times block sm:inline">options</span>
</h1>
<h1 class="font-poppins text-sm xl:text-lg 3xl:text-xl font-extrabold text-custom-gray flex items-center justify-center mt-6 tracking-[2px] 3xl:tracking-[4px]">
  ADD, PAUSE OR CANCEL YOUR SUBSCRIPTION, ANYTIME!
</h1>

<div class="flex flex-wrap mt-16">
  <!-- Daily Subscription -->
  <div class="w-full md:w-1/2 lg:w-1/3 pb-6">
    <div class="flex flex-col items-center text-center justify-between h-full md:border-r border-[#707070] pb-6">
      <!-- Image -->
      <div class="flex justify-center items-center w-full mb-5 px-5">
        <img class="w-full h-auto" src="../../assets/images/juice-bottle.png" alt="Juice Bottle" />
      </div>

      <!-- Headings -->
      <div class="flex flex-col items-center text-center mt-5">
        <h2 class="text-sm xl:text-lg 3xl:text-custom-font-20 lg:leading-custom-line-30 font-poppins font-extrabold text-custom-gray tracking-widest mb-10 2xl:mb-20">POPULAR</h2>
        <h1 class="text-3xl lg:text-[30px] 2xl:text-[45px] text-custom-gray">
          <span class="font-times block mb-2">DAILY</span>
          <span class="font-times italic">subscription</span>
        </h1>
      </div>

      <!-- Paragraph -->
      <div class="mt-10">
        <p class="text-sm 3xl:text-custom-font-20 2xl:leading-custom-line-38 font-lato px-4 3xl:px-4 text-[#343434]">
          With our Daily Subscription, you can select your favorite juices and have them delivered to your doorstep every day. Enjoy the convenience of fresh juice daily.          </p>
      </div>

      <!-- Button -->
      <button (click)="startSubscription('daily')" class="mt-16 border-2 border-[#1D1A1A] text-custom-gray py-2 px-4 font-poppins text-sm 3xl:text-lg w-64 lg:w-60 2xl:w-72 3xl:w-80">
        START YOUR SUBSCRIPTION
      </button>
    </div>
  </div>

  <!-- Alternate Day Subscription -->
  <div class="w-full md:w-1/2 lg:w-1/3 pb-6">
    <div class="flex flex-col items-center text-center justify-between h-full lg:border-r border-[#707070] pb-6">
      <!-- Image -->
      <div class="flex justify-center items-center w-full mb-5 px-5">
        <img class="w-full h-auto" src="../../assets/images/juice-bottle.png" alt="Juice Bottle" />
      </div>

      <!-- Headings -->
      <div class="flex flex-col items-center text-center mt-5">
        <h2 class="text-sm xl:text-lg 3xl:text-custom-font-20 lg:leading-custom-line-30 font-poppins font-extrabold text-custom-gray tracking-widest mb-10 2xl:mb-20">BEST SELLER</h2>
        <h1 class="text-3xl lg:text-[30px] 2xl:text-[45px] text-custom-gray">
          <span class="font-times block mb-2">ALTERNATE DAY</span>
          <span class="font-times italic">subscription</span>
        </h1>
      </div>

      <!-- Paragraph -->
      <div class="mt-10">
        <p class="text-sm 3xl:text-custom-font-20 2xl:leading-custom-line-38 font-lato px-6 3xl:px-4 text-[#343434]">
          Our Alternate Day Subscription allows you to choose your preferred juices and receive deliveries every other day. Perfect for those who enjoy fresh juice regularly but not necessarily every day.          </p>
      </div>

      <!-- Button -->
      <button (click)="startSubscription('alternate-day')" class="mt-8 border-2 border-[#1D1A1A] text-custom-gray py-2 px-4 font-poppins text-sm 3xl:text-lg w-64 lg:w-60 2xl:w-72 3xl:w-80">
        START YOUR SUBSCRIPTION
      </button>
    </div>
  </div>

  <!-- Weekly Subscription -->
  <div class="w-full md:w-1/2 lg:w-1/3 pb-6">
    <div class="flex flex-col items-center text-center justify-between h-full pb-6">
      <!-- Image -->
      <div class="flex justify-center items-center w-full mb-5 px-5">
        <img class="w-full h-auto" src="../../assets/images/juice-bottle.png" alt="Juice Bottle" />
      </div>

      <!-- Headings -->
      <div class="flex flex-col items-center text-center mt-5">
        <h2 class="text-sm xl:text-lg 3xl:text-custom-font-20 lg:leading-custom-line-30 font-poppins font-extrabold text-custom-gray tracking-widest mb-10 2xl:mb-20">MOST LOVED</h2>
        <h1 class="text-3xl lg:text-[30px] 2xl:text-[45px] text-custom-gray">
          <span class="font-times block mb-2">WEEKLY</span>
          <span class="font-times italic">subscription</span>
        </h1>
      </div>

      <!-- Paragraph -->
      <div class="mt-10">
        <p class="text-sm 3xl:text-custom-font-20 2xl:leading-custom-line-38 font-lato px-4 3xl:px-4 text-[#343434]">
          Customize your juice experience with our Weekly Subscription. Choose your favorite juices and select the specific days you want them delivered. Enjoy flexibility and personalization to fit your schedule.          </p>
      </div>

      <!-- Button -->
      <button (click)="startSubscription('weekly')" class="mt-8 border-2 border-[#1D1A1A] text-custom-gray py-2 px-4 font-poppins text-sm 3xl:text-lg w-64 lg:w-60 2xl:w-72 3xl:w-80">
        START YOUR SUBSCRIPTION
      </button>
    </div>
  </div>
</div>



</div>
</body>
<app-footer></app-footer>