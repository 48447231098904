import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-bg-green',
  templateUrl: './footer-bg-green.component.html',
  styleUrl: './footer-bg-green.component.css'
})
export class FooterBgGreenComponent {
  Developed_by="@MindCodes";
}
