<div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 font-poppins">
    <div class="bg-white rounded-lg shadow-lg w-[95%] max-w-md p-6 relative">
      <button class="absolute top-4 right-4 text-custom-gray hover:text-black text-2xl font-bold focus:outline-none" (click)="closePopup()">×</button>
      
      <div class="flex items-center space-x-4 mb-6">
        <div class="w-16 h-16 rounded-full bg-custom-gray flex justify-center items-center text-white text-xl font-bold">
          {{ address.first_name.charAt(0) }}{{ address.last_name.charAt(0) }}
        </div>
        <div>
          <h2 class="text-2xl font-semibold text-black">{{ address.first_name }} {{ address.last_name }}</h2>
        </div>
      </div>
  
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <!-- Address -->
        <div class="p-4 bg-gray-100 rounded-md shadow-sm flex items-center space-x-3">
          <i class="fas fa-map-marker-alt text-custom-gray"></i>
          <div>
            <h3 class="font-medium text-gray-600 text-sm mb-1">Address</h3>
            <p class="text-gray-800 text-sm">{{ address.address }}</p>
          </div>
        </div>
  
        <!-- Area -->
        <div class="p-4 bg-gray-100 rounded-md shadow-sm flex items-center space-x-3">
          <i class="fas fa-map text-custom-gray"></i>
          <div>
            <h3 class="font-medium text-gray-600 text-sm mb-1">Area</h3>
            <p class="text-gray-800 text-sm">{{ address.area }}</p>
          </div>
        </div>
  
        <!-- City -->
        <div class="p-4 bg-gray-100 rounded-md shadow-sm flex items-center space-x-3">
          <i class="fas fa-city text-custom-gray"></i>
          <div>
            <h3 class="font-medium text-gray-600 text-sm mb-1">City</h3>
            <p class="text-gray-800 text-sm">{{ address.city }}</p>
          </div>
        </div>
  
        <!-- State -->
        <div class="p-4 bg-gray-100 rounded-md shadow-sm flex items-center space-x-3">
          <i class="fas fa-flag text-custom-gray"></i>
          <div>
            <h3 class="font-medium text-gray-600 text-sm mb-1">State</h3>
            <p class="text-gray-800 text-sm">{{ address.state }}</p>
          </div>
        </div>
  
        <!-- Zip Code -->
        <div class="p-4 bg-gray-100 rounded-md shadow-sm flex items-center space-x-3">
          <i class="fas fa-mail-bulk text-custom-gray"></i>
          <div>
            <h3 class="font-medium text-gray-600 text-sm mb-1">Zip Code</h3>
            <p class="text-gray-800 text-sm">{{ address.zip_code }}</p>
          </div>
        </div>
  
        <!-- Mobile -->
        <div class="p-4 bg-gray-100 rounded-md shadow-sm flex items-center space-x-3">
          <i class="fas fa-phone text-custom-gray"></i>
          <div>
            <h3 class="font-medium text-gray-600 text-sm mb-1">Mobile</h3>
            <p class="text-gray-800 text-sm">{{ address.mobile }}</p>
          </div>
        </div>
      </div>
  
    </div>
  </div>
  