<body class="dark:bg-white">
<div class="overflow-hidden">
  <div class="flex flex-col min-h-screen bg-white-100">
    <app-header></app-header>
    <div class="flex-grow flex justify-center items-center">
      <div class="relative py-3 sm:mx-auto sm:max-w-xl">
        <div
          class="absolute inset-0 -skew-y-6 transform bg-[#CCD56A] shadow-lg sm:-rotate-6 sm:skew-y-0 sm:rounded-3xl"
        ></div>
        <div
          class="relative bg-white px-4 py-10 shadow-lg sm:rounded-3xl sm:p-20"
        >
          <div class="mx-auto max-w-md">
            <div class="text-center">
              <div class="flex items-center justify-start mb-4">
                <button
                  (click)="goBack()"
                  class="text-black-600 dark:text-black hover:text-[#CCD56A] focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                    />
                  </svg>
                </button>
              </div>

              <h1
                class="text-4xl font-times mb-4 dark:text-black"
                *ngIf="!otpSent && !otpVerified"
              >
                Forgot Password
              </h1>
              <h1
                class="text-4xl font-times mb-4 dark:text-black"
                *ngIf="otpSent && !otpVerified"
              >
                Verify OTP
              </h1>
              <h1
                class="text-4xl font-times mb-4 dark:text-black"
                *ngIf="otpVerified"
              >
                Reset Password
              </h1>

              <!-- Forgot Password Form -->
              <form
                *ngIf="!otpSent && !otpVerified"
                [formGroup]="forgotPasswordForm"
                class="divide-y divide-gray-200"
              >
                <div
                  class="space-y-4 py-3 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7"
                >
                  <div class="relative">
                    <input
                      type="email"
                      formControlName="email"
                      class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-times"
                      placeholder="Enter Email Address"
                      id="email"
                      required
                    />
                    <label
                      for="email"
                      class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                    >
                      Email Address
                    </label>
                    <div
                      *ngIf="
                        forgotPasswordForm.get('email')?.invalid &&
                        forgotPasswordForm.get('email')?.touched
                      "
                      class="text-red-500 text-sm mt-1"
                    >
                      <span
                        *ngIf="
                          forgotPasswordForm.get('email')?.hasError('required')
                        "
                      >
                        Email is required.
                      </span>
                      <span
                        *ngIf="
                          forgotPasswordForm.get('email')?.hasError('email')
                        "
                      >
                        Invalid email address.
                      </span>
                    </div>
                  </div>
                  <div class="flex justify-center mt-2">
                    <button
                      type="button"
                      [disabled]="isLoading"
                      (click)="handleSendOtp()"
                      class="bg-custom-gray hover:bg-gray-900 text-custom-text-color-button font-normal lg:font-medium py-1 lg:py-2 px-2 lg:px-4 font-times text-sm lg:text-lg tracking-widest w-fit flex items-center justify-center"
                    >
                      <span
                        *ngIf="isLoading"
                        class="border-t-2 border-white border-solid rounded-full w-4 h-4 mr-2 animate-spin"
                        style="border-top-color: white"
                      ></span>
                      <span *ngIf="!isLoading">Send OTP</span>
                    </button>
                  </div>
                </div>
              </form>

              <!-- OTP Verification -->
              <div *ngIf="otpSent && !otpVerified" class="space-y-4">
                <div
                  class="space-y-6 py-8 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7"
                >
                  <!-- OTP Input Fields -->
                  <div
                    class="relative flex justify-center items-center space-x-2"
                  >
                    <input
                      class="mt-2 focus:border-[#CCD56A] peer h-10 w-10 text-center border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-roboto"
                      id="input1"
                      type="text"
                      maxlength="1"
                      (keyup)="onKeyUp($event, 0)"
                      [(ngModel)]="verificationCode[0]"
                    />
                    <input
                      class="mt-2 focus:border-[#CCD56A] peer h-10 w-10 text-center border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-roboto"
                      id="input2"
                      type="text"
                      maxlength="1"
                      (keyup)="onKeyUp($event, 1)"
                      [(ngModel)]="verificationCode[1]"
                    />
                    <input
                      class="mt-2 focus:border-[#CCD56A] peer h-10 w-10 text-center border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-roboto"
                      id="input3"
                      type="text"
                      maxlength="1"
                      (keyup)="onKeyUp($event, 2)"
                      [(ngModel)]="verificationCode[2]"
                    />
                    <input
                      class="mt-2 focus:border-[#CCD56A] peer h-10 w-10 text-center border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-roboto"
                      id="input4"
                      type="text"
                      maxlength="1"
                      (keyup)="onKeyUp($event, 3)"
                      [(ngModel)]="verificationCode[3]"
                    />
                    <input
                      class="mt-2 focus:border-[#CCD56A] peer h-10 w-10 text-center border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-roboto"
                      id="input5"
                      type="text"
                      maxlength="1"
                      (keyup)="onKeyUp($event, 4)"
                      [(ngModel)]="verificationCode[4]"
                    />
                    <input
                      class="mt-2 focus:border-[#CCD56A] peer h-10 w-10 text-center border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-roboto"
                      id="input6"
                      type="text"
                      maxlength="1"
                      (keyup)="onKeyUp($event, 5)"
                      [(ngModel)]="verificationCode[5]"
                    />

                    <label
                      class="font-roboto peer-placeholder-shown:text-gray-440 absolute -top-3.5 left-0 text-sm text-gray-600 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                      style="z-index: 10"
                    >
                      Enter 6 Digit OTP
                    </label>
                  </div>

                  <div class="relative flex justify-center items-center">
                    <button
                      type="button"
                      (click)="handleVerifyOtp()"
                      class="bg-custom-gray hover:bg-gray-900 text-custom-text-color-button font-normal lg:font-medium py-1 lg:py-2 px-2 lg:px-4 font-times text-sm lg:text-lg tracking-widest w-fit"
                    >
                      Verify OTP
                    </button>
                  </div>
                  <div class="relative flex justify-center items-center">
                    <!-- Resend OTP Section -->
                    <ng-container *ngIf="resendOtpCountdown > 0">
                      <span class="ml-5 text-gray-600"
                        >Resend OTP in {{ resendOtpCountdown }} seconds</span
                      >
                    </ng-container>
                    <ng-container *ngIf="resendOtpCountdown === 0">
                      <a
                        (click)="handleSendOtp()"
                        class="ml-5 text-custom-gray hover:underline"
                      >
                        Resend OTP
                      </a>
                    </ng-container>
                  </div>
                </div>
              </div>

              <!-- Reset Password Form -->
              <form
                *ngIf="otpVerified"
                [formGroup]="forgotPasswordForm"
                class="divide-y divide-gray-200"
              >
                <div
                  class="space-y-4 py-3 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7"
                >
                  <!-- New Password -->
                  <div class="relative">
                    <input
                      type="password"
                      formControlName="new_password"
                      class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-times"
                      placeholder="New Password"
                      id="new_password"
                      required
                    />
                    <label
                      for="new_password"
                      class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                    >
                      New Password
                    </label>
                    <div *ngIf="forgotPasswordForm.get('new_password')?.touched && forgotPasswordForm.get('new_password')?.invalid">
                      <!-- <small class="text-red-600" *ngIf="forgotPasswordForm.get('new_password')?.errors?.['required']">
                        New password is required.
                      </small>
                      <small class="text-red-600" *ngIf="forgotPasswordForm.get('new_password')?.errors?.['minlength']">
                        New password must be at least 8 characters long.
                      </small>
                      <small class="text-red-600" *ngIf="forgotPasswordForm.get('new_password')?.errors?.['lowercase']">
                        New password must contain at least one lowercase letter.
                      </small>
                      <small class="text-red-600" *ngIf="forgotPasswordForm.get('new_password')?.errors?.['uppercase']">
                        New password must contain at least one uppercase letter.
                      </small>
                      <small class="text-red-600" *ngIf="forgotPasswordForm.get('new_password')?.errors?.['digit']">
                        New password must contain at least one digit.
                      </small>
                      <small class="text-red-600" *ngIf="forgotPasswordForm.get('new_password')?.errors?.['special']">
                        New password must contain at least one special symbol.
                      </small> -->
                    </div>
                  </div>
                  

                  <div class="flex justify-center mt-2">
                    <button
                      type="button"
                      (click)="handleResetPassword()"
                      class="bg-custom-gray hover:bg-gray-900 text-custom-text-color-button font-normal lg:font-medium py-1 lg:py-2 px-2 lg:px-4 font-times text-sm lg:text-lg tracking-widest w-fit"
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</body>