import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService, Product } from '../api.service'; // Import Product here
import { SubscriptionDataService, SubscriptionData } from '../services/subscription-data.service';
import { Router, NavigationEnd } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar'; // Import MatSnackBar

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {
  subscriptionData: SubscriptionData | null = null;

  showPopup = false;  // This is for the subscription benefits popup
  showLoginPopup = false;

  subscriptionType: string | null = null;
  subscriptionForm: FormGroup;
  products: Product[] = []; // Array to store products
  filteredQuantities: string[] = []; // Array to store filtered quantities
  minDateTime: string = '';

  selectedProductPrice: number | null = null; // Variable to store selected product price
  selectedProductMasterId: string | null = null; // Variable to store selected product master id

  showPrice = false; // New variable to manage price visibility

  private routerSubscription: Subscription;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private apiService: ApiService, // Inject the service
    private subscriptionDataService: SubscriptionDataService,
    private snackBar: MatSnackBar // Inject MatSnackBar
  ) {
   // Initialize form controls with disabled state
this.subscriptionForm = this.fb.group({
  product_name: ['', Validators.required],
  quantity: [{value: '', disabled: true}, Validators.required],
  start_date: ['', Validators.required],
});

// Update the quantity control's disabled state when product is selected
// Listen to changes in product selection
this.subscriptionForm.get('product_name')?.valueChanges.subscribe(selectedJuice => {
  this.updateQuantities(selectedJuice);
  this.checkToShowPrice(); // Call to check visibility of price
});

// Listen to changes in quantity selection
this.subscriptionForm.get('quantity')?.valueChanges.subscribe(selectedQuantity => {
  this.checkToShowPrice(); // Call to check visibility of price
});

 // Listen to route changes
 this.routerSubscription = this.router.events.subscribe(event => {
  if (event instanceof NavigationEnd) {
    // console.log('Navigated to:', event.urlAfterRedirects);
    if (!event.urlAfterRedirects.includes('/checkout')) {
      // console.log('Clearing subscription data because route changed');
      this.subscriptionDataService.clearSubscriptionData();
    }
  }
});

}

checkToShowPrice() {
  const selectedJuice = this.subscriptionForm.get('product_name')?.value;
  const selectedQuantity = this.subscriptionForm.get('quantity')?.value;

  if (selectedJuice && selectedQuantity) {
    this.showPrice = true; // Display price section
    this.fetchPrice(selectedJuice, selectedQuantity); // Fetch the price for selected juice and quantity
  } else {
    this.showPrice = false; // Hide price section
    this.selectedProductPrice = null; // Reset price
  }
}

fetchPrice(selectedJuice: string, selectedQuantity: string) {
  // Use the correct method from ApiService
  this.apiService.getProductPrice({
    product_name: selectedJuice,
    quantity: parseInt(selectedQuantity, 10), // Ensure quantity is a number
  }).subscribe(
    (priceResponse) => {
      this.selectedProductPrice = priceResponse.price;
    },
    (error) => {
      console.error('Error fetching price', error);
    }
  );
}


  ngOnInit() {
    this.setMinDateTime(); // Set the minimum date on initialization

    this.subscriptionType = this.route.snapshot.paramMap.get('type');
    this.loadProducts(); // Load products on init

    // Listen for changes in the juice selection
    this.subscriptionForm.get('product_name')?.valueChanges.subscribe(selectedJuice => {
      this.updateQuantities(selectedJuice);
    });

    // Listen for changes in the quantity selection
    this.subscriptionForm.get('quantity')?.valueChanges.subscribe(selectedQuantity => {
      if (!this.subscriptionForm.get('product_name')?.value) {
        this.openSnackBar('Please select a juice first to add quantity.', 'custom-snackbar');
        this.subscriptionForm.get('quantity')?.setValue(''); // Reset quantity selection
      }
    });
  }

  ngOnDestroy() {
    // Unsubscribe from the router event when the component is destroyed
    // console.log('Checkout component destroyed');

    this.routerSubscription.unsubscribe();
  }

  // Method to set minimum date and time dynamically
  setMinDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    this.minDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  

  // Fetch products from the API
  loadProducts() {
    this.apiService.getProducts().subscribe((products) => {
      this.products = products;
    });
  }

  // Function to get unique product names
  getUniqueProductNames(): string[] {
    const productNames = this.products.map(product => product.product_name);
    return Array.from(new Set(productNames)); // Filter unique product names
  }

  // Function to update quantities based on selected juice
  updateQuantities(selectedJuice: string) {
    const filteredProducts = this.products.filter(product =>
      product.product_name === selectedJuice
    );
  
    const quantities = filteredProducts.map(product => product.quantity);
    this.filteredQuantities = Array.from(new Set(quantities));
  
    const quantityControl = this.subscriptionForm.get('quantity');
    if (quantityControl) {
      if (quantities.length > 0) {
        quantityControl.enable();
      } else {
        quantityControl.disable();
      }
    }
  
    // Reset the price and id when quantity changes
    this.selectedProductMasterId = null;
    this.selectedProductPrice = null;
  }
  
  

  // Function to get formatted title
  getFormattedSubscriptionTitle(): string {
    switch (this.subscriptionType) {
      case 'daily':
        return 'DAILY';
      case 'alternate-day':
        return 'ALTERNATE DAY';
      case 'weekly':
        return 'WEEKLY';
      default:
        return '';
    }
  }

  handleQuantityClick() {
    const quantityControl = this.subscriptionForm.get('quantity');
  
    if (quantityControl?.disabled) {
      this.openSnackBar('Please select a juice first to add quantity.', 'custom-snackbar');
    }
  }
  


  onSubmit() {
    if (this.subscriptionForm.invalid) {
      const controls = this.subscriptionForm.controls;
  
      if (controls['product_name'].hasError('required')) {
        this.openSnackBar('Please select a juice.', 'custom-snackbar');
      } else if (controls['quantity'].hasError('required')) {
        this.openSnackBar('Please select a quantity.', 'custom-snackbar');
      } else if (controls['start_date'].hasError('required')) {
        this.openSnackBar('Please select the start date and time.', 'custom-snackbar');
      }
  
      return;
    }
  
    const selectedDateTime = new Date(this.subscriptionForm.get('start_date')?.value);
    const currentDateTime = new Date();
  
    if (
      selectedDateTime.toDateString() === currentDateTime.toDateString() &&
      selectedDateTime < currentDateTime
    ) {
      this.openSnackBar('Invalid time selected.', 'custom-snackbar');
      return;
    }
  
    const selectedJuice = this.subscriptionForm.get('product_name')?.value;
    const selectedQuantity = parseInt(this.subscriptionForm.get('quantity')?.value, 10);


  
    // Step 1: Fetch product price and product_master_id first
    this.apiService.getProductPrice({
      product_name: selectedJuice,
      quantity: selectedQuantity,
    }).subscribe(
      (priceResponse) => {
        this.selectedProductPrice = priceResponse.price;
        this.selectedProductMasterId = priceResponse.product_master_id;
  
        const subscriptionData: SubscriptionData = {
          ...this.subscriptionForm.value,
          subscription_type: this.subscriptionType,
          price: this.selectedProductPrice,
          product_master_id: this.selectedProductMasterId,
        };

        if (!this.isLoggedIn()) {
          // Show popup for login if user is not logged in
          // this.openLoginPopup();
          // return;
          this.router.navigate(['/login']);

        }      
  
        // Step 2: Now call checkSubscription API with valid product_master_id
        this.apiService.checkSubscription(subscriptionData).subscribe(
          (response) => {
            if (response.detail === "Subscription already exists") {
              this.openSnackBar(response.detail, 'custom-snackbar');
            } else {
              // Step 3: Proceed to navigate to checkout if subscription does not exist
              this.subscriptionDataService.setSubscriptionData(subscriptionData);
              this.router.navigate(['/checkout']);
            }
          },
          (error) => {
            // this.openSnackBar('Failed to check subscription. Please try again.', 'custom-snackbar');
            this.router.navigate(['/login']);

          }
        );
      },
      (error) => {
        this.openSnackBar('Failed to fetch product price. Please try again.', 'custom-snackbar');
      }
    );
  }
  
  isLoggedIn(): boolean {
    // Check for the user's session or authentication state
    return !!localStorage.getItem('session_user_id');
  }
  
  openLoginPopup() {
    // Logic to show the login popup
    this.showLoginPopup = true;
  }

  onClosePopup() {
    this.showLoginPopup = false;
  }
  
  openPopup(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.showPopup = true;
  }
  

  // Method to handle product name change
  onProductNameChange(): void {
    const selectedJuice = this.subscriptionForm.get('product_name')?.value;
    this.subscriptionForm.patchValue({
      quantity: '',
    });
    this.updateQuantities(selectedJuice); // Update quantities and fetch price
  }
  

  openSnackBar(message: string, panelClass: string) {
    const config = {
      duration: 3000, // Duration of the snackbar
      panelClass: [panelClass],
    };
    this.snackBar.open(message, 'Close', config);
  }
}