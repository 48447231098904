import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-address-details-popup',
  templateUrl: './address-details-popup.component.html',
  styleUrl: './address-details-popup.component.css'
})
export class AddressDetailsPopupComponent {
  @Input() address: any;
  @Output() close = new EventEmitter<void>(); // Output event for closing the popup

  closePopup() {
    this.close.emit(); // Emit the close event to the parent
  }
}
