import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.css']
})
export class LoginPopupComponent {
  @Output() closePopup = new EventEmitter<void>();

  close() {
    this.closePopup.emit();
  }

  onClosePopup() {
    this.closePopup.emit();
  }
  
}
