<!-- cart component goes here -->

<app-above-header></app-above-header>
<app-header></app-header>
<body class="bg-white h-screen">
<div class="container mx-auto p-4 max-w-[1200px]">
  <div
    *ngIf="!isEmpty; else emptyCart"
    class="grid grid-cols-1 lg:grid-cols-3 gap-4"
  >
    <div class="col-span-2">
      <div class="mt-4 p-4 border rounded">
        <div class="flex justify-between border-b-2 border-custom-teal pb-4">
          <h2 class="font-medium text-2xl text-custom-gray font-times">Shopping Cart</h2>
          <!-- Select All Toggle -->
          <div class="flex items-center">
            <label
              for="toggleThree"
              class="flex items-center cursor-pointer select-none text-custom-gray"
            >
              <label class="mr-2 font-times">{{
                selectAll ? "Deselect all" : "Select all"
              }}</label>
              <div class="relative">
                <input
                  type="checkbox"
                  id="toggleThree"
                  class="peer sr-only"
                  [(ngModel)]="selectAll"
                  (change)="toggleSelectAll()"
                />
                <div
                  class="block h-6 rounded-full bg-gray-300  w-12"
                ></div>
                <div
                  class="absolute flex items-center justify-center w-5 h-5 transition bg-white  rounded-full dot  left-1 top-0.5 peer-checked:translate-x-full peer-checked:bg-custom-gray"
                >
                  <!-- Tick Icon -->
                  <svg
                    *ngIf="selectAll"
                    width="11"
                    height="8"
                    viewBox="0 0 11 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.4"
                    />
                  </svg>

                  <!-- Cross Icon -->
                  <svg
                    *ngIf="!selectAll"
                    class="w-4 h-4 stroke-current"
                    fill="none"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </div>
              </div>
            </label>
          </div>
        </div>

        <!-- Items List -->
        <div
          *ngFor="let item of cartItems"
          class="grid sm:grid-cols-3 gap-4 sm:gap-12 items-center pb-4 mb-4 mt-5 sm:mt-0 hover:bg-gray-50  transition duration-300 p-4 rounded-lg border border-gray-200 shadow-sm sm:border-none sm:shadow-none"
        >
         <!-- Checkbox and Image Column -->
<div class="flex sm:col-span-1 items-center justify-between sm:justify-start sm:space-x-6">
    <!-- Checkbox aligned to the start (left side) on all screens -->
    <input
      type="checkbox"
      class="form-checkbox w-4 h-4"
      [(ngModel)]="item.selected"
      (change)="calculateSubtotal()"
    />
    
    <!-- Image centered on mobile screens and left-aligned on larger screens -->
    <div class="flex-grow flex justify-center sm:justify-start">
      <div class="juice-bottle-image w-40 h-40 bg-gray-100"></div>
    </div>
  </div>
  

          <!-- Product Details Column -->
          <div class="sm:col-span-1 mt-4 sm:mt-0 text-center sm:text-left font-poppins">
            <h3 class="text-xl font-medium text-custom-gray  mb-1">
              {{ item.product_name }}
            </h3>
            <p class="text-lg text-gray-600  mb-2 text-nowrap">
              {{
                item.subscription_type === "daily"
                  ? "Daily Subscription"
                  : item.subscription_type === "alternate-day"
                  ? "Alternate Day Subscription"
                  : item.subscription_type === "weekly"
                  ? "Weekly Subscription"
                  : "Unknown Subscription Type"
              }}
              | {{ item.quantity }} ML
            </p>
            <p class="text-red-600 font-bold text-xl">₹{{ item.price }}</p>
          </div>

          <!-- Delete Button Column -->
          <div
            class="sm:col-span-1 mt-4 sm:mt-0 flex justify-center sm:justify-end"
          >
            <button
              (click)="deleteItem(item.cart_id)"
              class="text-custom-gray  hover:underline flex items-center space-x-2 font-poppins"
            >
              <i class="fas fa-trash-alt text-lg"></i>
              <span>Delete</span>
            </button>
          </div>
        </div>

        <hr class="my-4" />

        <div
          *ngIf="selectedItemsCount > 0; else noItemsSelected1"
          class="flex justify-end items-center mb-3 text-xl font-poppins"
        >
          <span class="text-gray-700 "
            >Subtotal ({{ selectedItemsCount }} items):</span
          >&nbsp;
          <span class="text-gray-900  font-medium">₹{{ subtotal }}</span>
        </div>

        <ng-template #noItemsSelected1>
          <div class="flex justify-end items-center mb-3 text-lg text-gray-700  font-poppins">
            No items selected
          </div>
        </ng-template>
      </div>
    </div>
    <!-- Cart Summary Section -->
    <div class="col-span-1">
      <div class="mt-4 p-5 border rounded">
        <div
          class="flex items-center justify-between mb-4 border-b-2 border-custom-teal pb-3"
        >
          <h2 class="font-medium text-2xl font-times text-custom-gray">Cart Summary</h2>
        </div>
        <!-- Summary Details Go Here -->

        <!-- Subtotal and Total Display -->
        <!-- Second Subtotal Display with Unique Template -->
        <div
          *ngIf="selectedItemsCount > 0; else noItemsSelected2"
          class="flex justify-between items-center mb-3 text-xl font-poppins"
        >
          <span class="text-gray-700 "
            >Subtotal ({{ selectedItemsCount }} items):</span
          >&nbsp;
          <span class="text-red-600 font-bold">₹{{ subtotal }}</span>
        </div>

        <ng-template #noItemsSelected2>
          <div
            class="flex justify-start items-center mb-3 text-lg text-gray-700  font-poppins"
          >
            No items selected
          </div>
        </ng-template>

        <div class="flex items-center justify-center mt-5">
          <button
          (click)="proceedToCheckout()"
            class="mb-4 bg-custom-gray hover:bg-gray-900 text-custom-text-color-button font-normal lg:font-medium py-1 lg:py-2 px-2 lg:px-4 font-poppins text-sm lg:text-lg tracking-widest w-fit"
          >
            Proceed to checkout
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-template #emptyCart>
    <div class="flex flex-col items-center justify-center mt-20 font-times">
      <div class="empty-cart-image w-60 h-60 sm:w-80 sm:h-80"></div>
      <h2 class="text-2xl font-medium text-gray-700 mb-2">
        Your Cart is Empty
      </h2>
      <p class="text-sm sm:text-lg text-gray-600 mb-5">
        Looks like you haven't added anything to your cart yet.
      </p>
      <button
        (click)="redirectToShop()"
        class="bg-custom-gray hover:bg-gray-900 text-custom-text-color-button font-medium py-2 px-4 tracking-wider"
      >
        Continue Shopping
      </button>
    </div>
  </ng-template>
</div>

<!-- Cart Component Template -->
<div *ngIf="showPopup" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
  <div class="bg-white rounded-xl shadow-xl p-4 sm:p-8 w-full sm:max-w-xl relative">
    <!-- Close Button (Icon) -->
    <button (click)="showPopup = false" class="absolute top-2 right-2 text-gray-500 hover:text-gray-800 transition duration-300 ease-in-out">
      <!-- Close Icon -->
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-7 h-7">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>

    <!-- Popup Content -->
    <h3 class="text-xl sm:text-2xl font-times font-bold mb-1 text-gray-800 text-center">Your Selected Items</h3>
    <p class="text-sm sm:text-lg mb-6 font-times text-gray-800 text-center border-b-2 border-custom-teal pb-4">Please Select Start Date Before Checkout!</p>

    <!-- Display only selected items -->
    <div *ngFor="let item of selectedItems" class="mb-6 flex flex-col sm:flex-row items-start space-y-4 sm:space-y-0 sm:space-x-4 font-poppins">
      <span class="text-custom-gray font-medium text-sm sm:text-lg">{{ item.product_name }} - {{
        item.subscription_type === "daily"
          ? "Daily Subscription"
          : item.subscription_type === "alternate-day"
          ? "Alternate Day Subscription"
          : item.subscription_type === "weekly"
          ? "Weekly Subscription"
          : "Unknown Subscription Type"
      }}</span>
      <input type="datetime-local" [(ngModel)]="item.start_date" 
      class="border-2 border-[#1D1A1A] text-custom-gray  py-2 px-4 font-poppins text-xs sm:text-lg w-full sm:w-64" 
      [attr.min]="minDate"
    />
    </div>
    
    <!-- Action Buttons -->
    <div class="flex justify-center space-x-4">
      <button (click)="confirmCheckout()" 
        class="bg-custom-gray hover:bg-gray-900 text-custom-text-color-button font-normal lg:font-medium py-1 lg:py-2 px-2 lg:px-4 font-poppins text-xs sm:text-sm lg:text-lg tracking-widest w-fit"
      >Submit</button>
    </div>
  </div>
</div>
</body> 
