import { Component } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  email: string = '';
  password: string = '';

  constructor(
    private apiService: ApiService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  onSubmit() {
    const loginData = {
      email: this.email,
      password: this.password,
    };

    this.apiService.login(loginData).subscribe({
      next: (response) => {
        if (response.message === 'Login successful') {
          localStorage.setItem('isLoggedIn', 'true');
          
          // Check session_role_id
          const sessionRoleId = response.session_role_id;
          if (sessionRoleId === 'DR6OBr8M' || sessionRoleId === 'm4I9bCyx') {
            // Navigate to '/product' route if role matches
            this.router.navigate(['/product']);
          } else {
            // Navigate to '/' route otherwise
            this.router.navigate(['/']);
          }

          this.openSnackBar('Login successful!', 'custom-snackbar');
        } else {
          // Handle unexpected response (e.g., incorrect credentials)
          this.openSnackBar(
            'Login failed: ' + response.message,
            'custom-snackbar'
          );
        }
      },
      error: (error) => {
        // Handle HTTP error
        const errorMessage = error.error.detail || 'Login failed!';
        this.openSnackBar(errorMessage, 'custom-snackbar');
      },
    });
  }

  openSnackBar(message: string, panelClass: string) {
    const config = new MatSnackBarConfig();
    config.duration = 3000; // Duration of the snackbar
    config.panelClass = [panelClass];
    this.snackBar.open(message, 'Close', config);
  }
}