<div class="footer-wrapper h-auto  mt-[-1px]">
  <footer class="footer px-4 py-8">
    <aside class="flex items-center justify-center">
      <div class="logo-background flex-shrink-0 flex justify-center items-center w-[390px] sm:w-[650px] lg:w-[743px] sm:mt-[100px] md:mt-[220px] lg:mt-[300px]">
        <!-- Logo content here -->
      </div>
    </aside>
  </footer>

  <footer class="footer text-[#414141] flex flex-col lg:flex-row gap-8 lg:gap-0 2xl:gap-16 ml-10 xl:ml-24 2xl:ml-0 justify-around mt-16">
    <nav class="space-y-2 mb-2 w-full max-w-xs">
      <h6 class="font-times text-3xl 2xl:text-5xl text-[#414141]">Shop</h6>
      <a class="link link-hover font-helvetica font-bold text-xl 2xl:text-2xl whitespace-nowrap">SUBSCRIPTION OPTIONS</a>
      <a class="link link-hover font-helvetica font-bold text-xl 2xl:text-2xl">JUICE OPTIONS</a>
      <a class="link link-hover font-helvetica font-bold text-xl 2xl:text-2xl">COMBO OFFERS</a>
      <a class="link link-hover font-times italic text-lg sm:text-2xl 2xl:text-3xl whitespace-nowrap">Developed By {{Developed_by}}</a>
    </nav>
    <nav class="space-y-2 mb-2 w-full max-w-xs">
      <h6 class="font-times text-3xl 2xl:text-5xl text-[#414141]">Learn</h6>
      <a class="link link-hover font-helvetica font-bold text-xl 2xl:text-2xl">ABOUT NIRDHARA</a>
      <a class="link link-hover font-helvetica font-bold text-xl 2xl:text-2xl">DELIVERY ZONES</a>
      <a class="link link-hover font-helvetica font-bold text-xl 2xl:text-2xl">CONTACT US</a>
      <a href="/T&C" class="link link-hover font-helvetica font-bold text-xl 2xl:text-2xl whitespace-nowrap">TERMS & CONDITIONS</a>
    </nav>
    <nav class="space-y-2 mb-2 w-full max-w-xs md:max-w-lg">
      <h6 class="font-times text-3xl 2xl:text-5xl text-[#414141]">Connect</h6>
      <a class="link link-hover font-helvetica font-bold text-xl 2xl:text-2xl">WHATSAPP</a>
      <a class="link link-hover font-helvetica font-bold text-xl 2xl:text-2xl">EMAIL</a>
      <a class="link link-hover font-helvetica font-bold text-xl 2xl:text-2xl">INSTAGRAM</a>
      <a class="link link-hover font-times italic text-lg sm:text-2xl 2xl:text-3xl">Designed by Five by Five Design</a>
    </nav>
  </footer>
</div>