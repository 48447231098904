<body class="dark:bg-white">
    <app-above-header></app-above-header>
    <app-header></app-header>
  
    <!-- Centered Coming Soon Section -->
    <div class="flex items-center justify-center h-[calc(100vh-23px-144px)] font-times overflow-hidden">
      <div class="text-center">
        <h1 class="text-4xl font-bold text-custom-gray dark:text-gray-800">Coming Soon</h1>
        <p class="text-xl text-gray-700 dark:text-gray-600 mt-4">We are currently working to bring something amazing!</p>
        
        <!-- Links Section -->
        <div class="mt-6 flex flex-col space-y-2 sm:flex-row sm:space-x-6 sm:space-y-0 font-poppins items-center justify-center">
          <a href="/refund_policy" class="sm:text-lg text-blue-700 hover:underline">Refund Policy</a>
          <a href="/return_policy" class="sm:text-lg text-blue-700 hover:underline">Return Policy</a>
          <a href="/shipping_policy" class="sm:text-lg text-blue-700 hover:underline">Shipping Policy</a>
        </div>
      </div>
    </div>
  </body>
  