<!DOCTYPE html>
<html>
  <head>
    <title>Checkout</title>
  </head>
  <body class="bg-white">
    <app-above-header></app-above-header>
    <app-header></app-header>
    <div class="container mx-auto p-4 max-w-[1200px]">
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <!-- Delivery Address Section -->
        <div class="col-span-2">
          <div class="p-4 rounded-sm border">
            <div
              class="grid grid-cols-2 sm:grid-cols-2 items-start sm:items-center justify-between mb-4 border-b-2 border-custom-teal pb-4"
            >
              <h2
                class="font-medium text-2xl text-custom-gray font-times mb-2 sm:mb-0 items-center justify-center col-span-1 sm:col-span-1"
              >
                Delivery Addresses
              </h2>
              <div
                class="flex flex-col sm:flex-row sm:space-x-5 space-y-2 sm:space-y-0"
              >
                <button
                  (click)="openAddressModal()"
                  class="border-2 border-[#1D1A1A]  text-custom-gray  py-2 px-4 font-poppins text-sm md:text-lg w-full sm:w-auto transition duration-300 ease-in-out"
                >
                  Add New Address
                </button>
                <button
                  (click)="toggleAddressChange()"
                  class="border-2 border-[#1D1A1A]  text-custom-gray  py-2 px-4 font-poppins text-sm md:text-lg w-full sm:w-auto transition duration-300 ease-in-out"
                >
                  {{ isChangeAddressMode ? "Close" : "Change Address" }}
                </button>
              </div>
            </div>
            <!-- Display current address -->
            <div *ngIf="currentAddress && !isChangeAddressMode">
              <div class="p-4 mb-4 flex items-center justify-between font-poppins text-custom-gray">
                <div class="sm:ml-2">
                  <h3 class="text-lg sm:text-xl font-medium">
                    {{ currentAddress.first_name }}
                    {{ currentAddress.last_name }}
                  </h3>
                  <p class="text-sm sm:text-lg">
                    {{ currentAddress.address }}, {{ currentAddress.area }}
                  </p>
                  <p class="text-sm sm:text-lg">
                    {{ currentAddress.city }}, {{ currentAddress.state }} -
                    {{ currentAddress.zip_code }}
                  </p>
                </div>
                <div
                  class="flex flex-col sm:flex-row sm:items-center gap-4 mt-2 sm:mr-10 font-poppins"
                >
                  <button
                    (click)="openEditModal(currentAddress)"
                    class="text-custom-gray  hover:underline flex items-center"
                  >
                    <i class="fas fa-edit mr-2 text-lg"></i> Edit
                  </button>
                  <button
                    (click)="deleteAddress(currentAddress.address_id)"
                    class="text-custom-gray  hover:underline flex items-center"
                  >
                    <i class="fas fa-trash-alt mr-2 text-lg"></i> Delete
                  </button>
                </div>
              </div>
            </div>
            <!-- Display all addresses with radio buttons -->
            <div *ngIf="isChangeAddressMode">
              <div
                *ngFor="let address of addresses"
                class=" text-custom-gray p-4 rounded-lg mb-4 shadow-md flex items-center justify-between font-poppins"
              >
                <div class="flex items-center ">
                  <input
                    type="radio"
                    name="selectedAddress"
                    [value]="address"
                    [(ngModel)]="selectedAddress"
                    class="mr-5 sm:mr-10"
                  />
                  <div>
                    <h3 class="text-lg sm:text-xl font-medium">
                      {{ address.first_name }} {{ address.last_name }}
                    </h3>
                    <p class="text-sm sm:text-lg">
                      {{ address.address }}, {{ address.area }}
                    </p>
                    <p class="text-sm sm:text-lg">
                      {{ address.city }}, {{ address.state }} -
                      {{ address.zip_code }}
                    </p>
                  </div>
                </div>
                <div
                  class="flex flex-col sm:flex-row sm:items-center gap-4 mt-2 sm:mr-10 font-poppins"
                >
                  <button
                    (click)="openEditModal(address)"
                    class="text-custom-gray  hover:underline flex items-center"
                  >
                    <i class="fas fa-edit mr-2 text-lg"></i> Edit
                  </button>
                  <button
                    (click)="deleteAddress(address.address_id)"
                    class="text-custom-gray  hover:underline flex items-center"
                  >
                    <i class="fas fa-trash-alt mr-2 text-lg"></i> Delete
                  </button>
                </div>
              </div>
              <div class="flex justify-center items-center">
                <button
                  (click)="useSelectedAddress()"
                  class="mb-4 border-2 border-[#1D1A1A]  text-custom-gray  py-2 px-4 font-poppins text-sm md:text-lg w-fit transition duration-300 ease-in-out"
                >
                  Use This Address
                </button>
              </div>
            </div>

            <div
              *ngIf="!addresses.length && !isChangeAddressMode"
              class="text-custom-gray"
            >
              No addresses found.
            </div>
          </div>

          <!-- Address Modal -->
          <div
            *ngIf="isModalOpen"
            class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 font-poppins"
          >
            <div
              class="bg-white p-5 sm:p-10 rounded-lg shadow-md max-w-xl w-full relative"
            >
              <button
                (click)="closeAddressModal()"
                class="absolute top-2 right-2 text-custom-gray hover:text-gray-800 focus:outline-none"
              >
                <!-- Close icon -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>

              <h2
                class="font-medium text-custom-gray text-2xl mb-0 sm:mb-2 border-b-2 border-custom-teal pb-2 sm:pb-4"
              >
                Add Address
              </h2>

              <form [formGroup]="addressForm" (ngSubmit)="onSubmit()">
                <div class="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-5 mt-3 sm:mt-5">
                  <!-- First Name -->
                  <div class="mb-2 sm:mb-6 relative">
                    <label
                      for="first_name"
                      class="block text-gray-700 mb-1 ml-6"
                      >First Name</label
                    >
                    <div class="flex items-center">
                      <i class="fas fa-user text-custom-gray mr-2"></i>
                      <input
                        id="first_name"
                        formControlName="first_name"
                        class="flex-1 min-w-0 w-full text-sm rounded-md border bg-white py-1 px-2 sm:py-2 sm:px-4 text-gray-600 shadow-sm outline-none focus:border-custom-teal focus:shadow-lg transition duration-300 ease-in-out"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('first_name')?.invalid &&
                        addressForm.get('first_name')?.touched
                      "
                      class="text-red-500 text-[12px] ml-6"
                    >
                      <div
                        *ngIf="addressForm.get('first_name')?.errors?.['required']"
                      >
                        First name is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('first_name')?.errors?.['pattern']"
                      >
                        First name must contain only letters.
                      </div>
                    </div>
                  </div>

                  <!-- Last Name -->
                  <div class="mb-2 sm:mb-6 relative">
                    <label for="last_name" class="block text-gray-700 mb-1 ml-6"
                      >Last Name</label
                    >
                    <div class="flex items-center">
                      <i class="fas fa-user text-custom-gray mr-2"></i>
                      <input
                        id="last_name"
                        formControlName="last_name"
                        class="flex-1 min-w-0 w-full text-sm rounded-md border bg-white py-1 px-2 sm:py-2 sm:px-4 text-gray-600 shadow-sm outline-none focus:border-custom-teal focus:shadow-lg transition duration-300 ease-in-out"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('last_name')?.invalid &&
                        addressForm.get('last_name')?.touched
                      "
                      class="text-red-500 text-[12px] ml-6"
                    >
                      <div
                        *ngIf="addressForm.get('last_name')?.errors?.['required']"
                      >
                        Last name is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('last_name')?.errors?.['pattern']"
                      >
                        Last name must contain only letters.
                      </div>
                    </div>
                  </div>

                  <!-- Alternate Mobile Number -->
                  <div class="mb-2 sm:mb-6 relative">
                    <label for="mobile" class="block text-gray-700 mb-1 ml-6"
                      >Mobile Number</label
                    >
                    <div class="flex items-center">
                      <i class="fas fa-phone text-custom-gray mr-2"></i>
                      <input
                        id="mobile"
                        formControlName="mobile"
                        class="flex-1 min-w-0 w-full text-sm rounded-md border bg-white py-1 px-2 sm:py-2 sm:px-4 text-gray-600 shadow-sm outline-none focus:border-custom-teal focus:shadow-lg transition duration-300 ease-in-out"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('mobile')?.invalid &&
                        addressForm.get('mobile')?.touched
                      "
                      class="text-red-500 text-[12px] ml-6"
                    >
                      <div
                        *ngIf="addressForm.get('mobile')?.errors?.['required']"
                      >
                        Mobile number is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('mobile')?.errors?.['pattern']"
                      >
                        Mobile number must be 10 digits.
                      </div>
                    </div>
                  </div>

                  <!-- Address -->
                  <div class="mb-2 sm:mb-6 relative">
                    <label for="address" class="block text-gray-700 mb-1 ml-6"
                      >Address</label
                    >
                    <div class="flex items-center">
                      <i class="fas fa-house text-custom-gray mr-2"></i>
                      <input
                        id="address"
                        formControlName="address"
                        class="flex-1 min-w-0 w-full text-sm rounded-md border bg-white py-1 px-2 sm:py-2 sm:px-4 text-gray-600 shadow-sm outline-none focus:border-custom-teal focus:shadow-lg transition duration-300 ease-in-out"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('address')?.invalid &&
                        addressForm.get('address')?.touched
                      "
                      class="text-red-500 text-[12px] ml-6"
                    >
                      <div
                        *ngIf="addressForm.get('address')?.errors?.['required']"
                      >
                        Address is required.
                      </div>
                    </div>
                  </div>

                  <!-- Area -->
                  <div class="mb-2 sm:mb-6 relative">
                    <label for="area" class="block text-gray-700 mb-1 ml-7"
                      >Area</label
                    >
                    <div class="flex items-center">
                      <i class="fas fa-house-user text-custom-gray mr-2"></i>
                      <input
                        id="area"
                        formControlName="area"
                        class="flex-1 min-w-0 w-full text-sm rounded-md border bg-white py-1 px-2 sm:py-2 sm:px-4 text-gray-600 shadow-sm outline-none focus:border-custom-teal focus:shadow-lg transition duration-300 ease-in-out"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('area')?.invalid &&
                        addressForm.get('area')?.touched
                      "
                      class="text-red-500 text-[12px] ml-7"
                    >
                      <div
                        *ngIf="addressForm.get('area')?.errors?.['required']"
                      >
                        Area is required.
                      </div>
                    </div>
                  </div>

                  <!-- State -->
                  <div class="mb-2 sm:mb-6 relative">
                    <label for="state" class="block text-gray-700 mb-1 ml-7"
                      >State</label
                    >
                    <div class="flex items-center">
                      <i class="fas fa-flag text-custom-gray mr-2"></i>
                      <input
                        id="state"
                        formControlName="state"
                        class="flex-1 min-w-0 w-full text-sm rounded-md border bg-white py-1 px-2 sm:py-2 sm:px-4 text-gray-600 shadow-sm outline-none focus:border-custom-teal focus:shadow-lg transition duration-300 ease-in-out"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('state')?.invalid &&
                        addressForm.get('state')?.touched
                      "
                      class="text-red-500 text-[12px] ml-7"
                    >
                      <div
                        *ngIf="addressForm.get('state')?.errors?.['required']"
                      >
                        State is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('state')?.errors?.['pattern']"
                      >
                        State must contain only letters and spaces.
                      </div>
                    </div>
                  </div>

                  <!-- City -->
                  <div class="mb-2 sm:mb-6 relative">
                    <label for="city" class="block text-gray-700 mb-1 ml-7"
                      >City</label
                    >
                    <div class="flex items-center">
                      <i class="fas fa-city text-custom-gray mr-2"></i>
                      <input
                        id="city"
                        formControlName="city"
                        class="flex-1 min-w-0 w-full text-sm rounded-md border bg-white py-1 px-2 sm:py-2 sm:px-4 text-gray-600 shadow-sm outline-none focus:border-custom-teal focus:shadow-lg transition duration-300 ease-in-out"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('city')?.invalid &&
                        addressForm.get('city')?.touched
                      "
                      class="text-red-500 text-[12px] ml-7"
                    >
                      <div
                        *ngIf="addressForm.get('city')?.errors?.['required']"
                      >
                        City is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('city')?.errors?.['invalidCity']"
                      >
                        Delivery in Pune city only
                      </div>
                    </div>
                  </div>

                  <!-- Zip Code -->
                  <div class="mb-2 sm:mb-6 relative">
                    <label for="zip_code" class="block text-gray-700 mb-1 ml-7"
                      >Zip Code</label
                    >
                    <div class="flex items-center">
                      <i
                        class="fas fa-map-marker-alt text-custom-gray mr-4 sm:mr-3"
                      ></i>
                      <input
                        id="zip_code"
                        formControlName="zip_code"
                        class="flex-1 min-w-0 w-full text-sm rounded-md border bg-white py-1 px-2 sm:py-2 sm:px-4 text-gray-600 shadow-sm outline-none focus:border-custom-teal focus:shadow-lg transition duration-300 ease-in-out"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('zip_code')?.invalid &&
                        addressForm.get('zip_code')?.touched
                      "
                      class="text-red-500 text-[12px] ml-7"
                    >
                      <div
                        *ngIf="addressForm.get('zip_code')?.errors?.['required']"
                      >
                        Zip code is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('zip_code')?.errors?.['pattern']"
                      >
                        Zip code must be 5-6 digits.
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Submit Button -->
                <div class="flex justify-center mt-2 sm:mt-6">
                  <button
                    type="submit"
                    class="bg-custom-gray hover:bg-gray-900 text-custom-text-color-button font-normal lg:font-medium py-1 lg:py-2 px-2 lg:px-4 font-times text-sm lg:text-lg tracking-widest w-fit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>

          <!-- Edit Modal -->
          <div
            *ngIf="isEditModalOpen"
            class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 font-poppins"
          >
            <div
            class="bg-white p-5 sm:p-10 rounded-lg shadow-md max-w-xl w-full relative"
            >
              <button
                (click)="closeEditModal()"
                class="absolute top-2 right-2 text-custom-gray hover:text-gray-800 focus:outline-none"
              >
                <!-- Close icon -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>

              <h2
              class="font-medium text-custom-gray text-2xl mb-0 sm:mb-2 border-b-2 border-custom-teal pb-2 sm:pb-4"
              >
                Edit Address
              </h2>

              <form [formGroup]="addressForm" (ngSubmit)="editSubmit()">
                <div class="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-5 mt-3 sm:mt-5">
                  <!-- First Name -->
                  <div class="mb-2 sm:mb-6 relative">
                    <label
                      for="first_name"
                      class="block text-gray-700 mb-1 ml-6"
                      >First Name</label
                    >
                    <div class="flex items-center">
                      <i class="fas fa-user text-custom-gray mr-2"></i>
                      <input
                        id="first_name"
                        formControlName="first_name"
                        class="flex-1 min-w-0 w-full text-sm rounded-md border bg-white py-1 px-2 sm:py-2 sm:px-4 text-gray-600 shadow-sm outline-none focus:border-custom-teal focus:shadow-lg transition duration-300 ease-in-out"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('first_name')?.invalid &&
                        addressForm.get('first_name')?.touched
                      "
                      class="text-red-500 text-[12px] ml-6"
                    >
                      <div
                        *ngIf="addressForm.get('first_name')?.errors?.['required']"
                      >
                        First name is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('first_name')?.errors?.['pattern']"
                      >
                        First name must contain only letters.
                      </div>
                    </div>
                  </div>

                  <!-- Last Name -->
                  <div class="mb-2 sm:mb-6 relative">
                    <label for="last_name" class="block text-gray-700 mb-1 ml-6"
                      >Last Name</label
                    >
                    <div class="flex items-center">
                      <i class="fas fa-user text-custom-gray mr-2"></i>
                      <input
                        id="last_name"
                        formControlName="last_name"
                        class="flex-1 min-w-0 w-full text-sm rounded-md border bg-white py-1 px-2 sm:py-2 sm:px-4 text-gray-600 shadow-sm outline-none focus:border-custom-teal focus:shadow-lg transition duration-300 ease-in-out"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('last_name')?.invalid &&
                        addressForm.get('last_name')?.touched
                      "
                      class="text-red-500 text-[12px] ml-6"
                    >
                      <div
                        *ngIf="addressForm.get('last_name')?.errors?.['required']"
                      >
                        Last name is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('last_name')?.errors?.['pattern']"
                      >
                        Last name must contain only letters.
                      </div>
                    </div>
                  </div>

                  <!-- Mobile Number -->
                  <div class="mb-2 sm:mb-6 relative">
                    <label for="mobile" class="block text-gray-700 mb-1 ml-6"
                      >Mobile Number</label
                    >
                    <div class="flex items-center">
                      <i class="fas fa-phone text-custom-gray mr-2"></i>
                      <input
                        id="mobile"
                        formControlName="mobile"
                        class="flex-1 min-w-0 w-full text-sm rounded-md border bg-white py-1 px-2 sm:py-2 sm:px-4 text-gray-600 shadow-sm outline-none focus:border-custom-teal focus:shadow-lg transition duration-300 ease-in-out"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('mobile')?.invalid &&
                        addressForm.get('mobile')?.touched
                      "
                      class="text-red-500 text-[12px] ml-6"
                    >
                      <div
                        *ngIf="addressForm.get('mobile')?.errors?.['required']"
                      >
                        Mobile number is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('mobile')?.errors?.['pattern']"
                      >
                        Mobile number must be 10 digits.
                      </div>
                    </div>
                  </div>

                  <!-- Address -->
                  <div class="mb-2 sm:mb-6 relative">
                    <label for="address" class="block text-gray-700 mb-1 ml-6"
                      >Address</label
                    >
                    <div class="flex items-center">
                      <i class="fas fa-house text-custom-gray mr-2"></i>
                      <input
                        id="address"
                        formControlName="address"
                        class="flex-1 min-w-0 w-full text-sm rounded-md border bg-white py-1 px-2 sm:py-2 sm:px-4 text-gray-600 shadow-sm outline-none focus:border-custom-teal focus:shadow-lg transition duration-300 ease-in-out"
                      />
                    </div>
                    <div    
                      *ngIf="
                        addressForm.get('address')?.invalid &&
                        addressForm.get('address')?.touched
                      "
                      class="text-red-500 text-[12px] ml-6"
                    >
                      <div
                        *ngIf="addressForm.get('address')?.errors?.['required']"
                      >
                        Address is required.
                      </div>
                    </div>
                  </div>

                  <!-- Area -->
                  <div class="mb-2 sm:mb-6 relative">
                    <label for="area" class="block text-gray-700 mb-1 ml-7"
                      >Area</label
                    >
                    <div class="flex items-center">
                      <i class="fas fa-house-user text-custom-gray mr-2"></i>
                      <input
                        id="area"
                        formControlName="area"
                        class="flex-1 min-w-0 w-full text-sm rounded-md border bg-white py-1 px-2 sm:py-2 sm:px-4 text-gray-600 shadow-sm outline-none focus:border-custom-teal focus:shadow-lg transition duration-300 ease-in-out"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('area')?.invalid &&
                        addressForm.get('area')?.touched
                      "
                      class="text-red-500 text-[12px] ml-7"
                    >
                      <div
                        *ngIf="addressForm.get('area')?.errors?.['required']"
                      >
                        Area is required.
                      </div>
                    </div>
                  </div>

                  <!-- State -->
                  <div class="mb-2 sm:mb-6 relative">
                    <label for="state" class="block text-gray-700 mb-1 ml-7"
                      >State</label
                    >
                    <div class="flex items-center">
                      <i class="fas fa-flag text-custom-gray mr-2"></i>
                      <input
                        id="state"
                        formControlName="state"
                        class="flex-1 min-w-0 w-full text-sm rounded-md border bg-white py-1 px-2 sm:py-2 sm:px-4 text-gray-600 shadow-sm outline-none focus:border-custom-teal focus:shadow-lg transition duration-300 ease-in-out"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('state')?.invalid &&
                        addressForm.get('state')?.touched
                      "
                      class="text-red-500 text-[12px] ml-7"
                    >
                      <div
                        *ngIf="addressForm.get('state')?.errors?.['required']"
                      >
                        State is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('state')?.errors?.['pattern']"
                      >
                        State must contain only letters and spaces.
                      </div>
                    </div>
                  </div>

                  <!-- City -->
                  <div class="mb-2 sm:mb-6 relative">
                    <label for="city" class="block text-gray-700 mb-1 ml-7"
                      >City</label
                    >
                    <div class="flex items-center">
                      <i class="fas fa-city text-custom-gray mr-2"></i>
                      <input
                        id="city"
                        formControlName="city"
                        class="flex-1 min-w-0 w-full text-sm rounded-md border bg-white py-1 px-2 sm:py-2 sm:px-4 text-gray-600 shadow-sm outline-none focus:border-custom-teal focus:shadow-lg transition duration-300 ease-in-out"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('city')?.invalid &&
                        addressForm.get('city')?.touched
                      "
                      class="text-red-500 text-[12px] ml-7"
                    >
                      <div
                        *ngIf="addressForm.get('city')?.errors?.['required']"
                      >
                        City is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('city')?.errors?.['invalidCity']"
                      >
                        Delivery in Pune city only
                      </div>
                    </div>
                  </div>

                  <!-- Zip Code -->
                  <div class="mb-2 sm:mb-6 relative">
                    <label for="zip_code" class="block text-gray-700 mb-1 ml-7"
                      >Zip Code</label
                    >
                    <div class="flex items-center">
                      <i
                      class="fas fa-map-marker-alt text-custom-gray mr-4 sm:mr-3"
                      ></i>
                      <input
                        id="zip_code"
                        formControlName="zip_code"
                        class="flex-1 min-w-0 w-full text-sm rounded-md border bg-white py-1 px-2 sm:py-2 sm:px-4 text-gray-600 shadow-sm outline-none focus:border-custom-teal focus:shadow-lg transition duration-300 ease-in-out"
                      />
                    </div>
                    <div
                      *ngIf="
                        addressForm.get('zip_code')?.invalid &&
                        addressForm.get('zip_code')?.touched
                      "
                      class="text-red-500 text-[12px] ml-7"
                    >
                      <div
                        *ngIf="addressForm.get('zip_code')?.errors?.['required']"
                      >
                        Zip code is required.
                      </div>
                      <div
                        *ngIf="addressForm.get('zip_code')?.errors?.['pattern']"
                      >
                        Zip code must be 5 digits.
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Submit Button -->
                <div class="flex justify-center mt-2 sm:mt-6">
                  <button
                    type="submit"
                    class="bg-custom-gray hover:bg-gray-900 text-custom-text-color-button font-normal lg:font-medium py-1 lg:py-2 px-2 lg:px-4 font-times text-sm lg:text-lg tracking-widest w-fit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>

          <!-- Payment Method Section -->
          <div class="mt-4 p-4 border rounded">
            <h2
              class="font-medium text-custom-gray text-2xl font-times mb-2 border-b-2 border-custom-teal  pb-4"
            >
              Payment Method
            </h2>
            <!-- Payment Form Goes Here -->
            <div
              class="flex items-center justify-between p-4 border-b cursor-pointer transition-colors hover:bg-gray-50 "
              (click)="togglePaymentMethod()"
            >
              <div class="flex items-center gap-2">
                <input
                  type="radio"
                  name="payment"
                  [(ngModel)]="selectedPayment"
                  [value]="'razorpay'"
                  id="razorpay-radio"
                  class="mr-2"
                />
                <span class="text-xl font-medium text-custom-gray  font-poppins">Razorpay</span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-gray-500  transition-transform"
                [ngClass]="{ 'rotate-180': isRazorpaySelected }"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </div>
            <div
              *ngIf="isRazorpaySelected"
              class="sm:flex items-center mt-2 p-4 text-gray-600 text-sm"
            >
              <!-- Image Column -->
              <div class="razor-pay-image w-40 h-20 mr-4"></div>

              <!-- Text Column -->
              <p class="sm:flex-1 sm:text-left">
                You will be redirected to the Razorpay website to complete your
                purchase securely.
              </p>
            </div>
          </div>
<!-- Review Items and Delivery Section -->
<div class="mt-4 p-4 border rounded">
  <h2 class="font-medium text-custom-gray text-2xl font-times mb-2 border-b-2 border-custom-teal   pb-4">
    Review Items and Delivery
  </h2>

  <!-- Render based on subscriptionData -->
  <div *ngIf="subscriptionData; else cartItemsSection" class="grid grid-cols-1 sm:grid-cols-2 gap-4 items-center justify-center pb-2 mb-2 hover:bg-gray-50  transition duration-300 rounded-lg p-4">
    <!-- Image Column -->
    <div class="flex items-start justify-center sm:justify-start">
      <div class="juice-bottle-image w-40 h-40"></div>
    </div>

    <!-- Product Details Column -->
    <div class="font-poppins">
      <h3 class="text-xl font-medium text-center sm:text-left text-custom-gray   mb-1">
        {{ subscriptionData.product_name }}
      </h3>
      <p class="text-lg text-gray-600   mb-2">
        {{
          subscriptionData.subscription_type === "daily"
            ? "Daily Subscription"
            : subscriptionData.subscription_type === "alternate-day"
            ? "Alternate Day Subscription"
            : subscriptionData.subscription_type === "weekly"
            ? "Weekly Subscription"
            : "Unknown Subscription Type"
        }}
        | {{ subscriptionData.quantity }} ML
      </p>
      <p class="text-lg text-gray-600   mb-2 sm:text-nowrap">
        Delivery From : {{ subscriptionData.start_date | date : "d MMMM yyyy hh:mm a" }}
      </p>
      <p class="text-red-600 font-bold text-xl">
        ₹{{ subscriptionData.price }}
      </p>
    </div>
  </div>

  <!-- Fallback for selectedCartItems -->
  <ng-template #cartItemsSection>
    <div *ngIf="selectedCartItems.length" class="grid grid-cols-1 sm:grid-cols-2 gap-4 items-center justify-center pb-2 mb-2 hover:bg-gray-50 transition duration-300 rounded-lg p-4 font-poppins">
      <ng-container *ngFor="let item of selectedCartItems">
        <!-- Image Column -->
        <div class="flex items-start justify-center sm:justify-start">
          <div class="juice-bottle-image w-40 h-40"></div>
        </div>

        <!-- Product Details Column -->
        <div>
          <h3 class="text-xl font-medium text-center sm:text-left text-gray-800  mb-1">
            {{ item.product_name }}
          </h3>
          <p class="text-lg text-gray-600  mb-2">
            {{
              item.subscription_type === "daily"
                ? "Daily Subscription"
                : item.subscription_type === "alternate-day"
                ? "Alternate Day Subscription"
                : item.subscription_type === "weekly"
                ? "Weekly Subscription"
                : "Unknown Subscription Type"
            }}
            | {{ item.quantity }} ML
          </p>
          <p class="text-lg text-gray-600   mb-2 sm:text-nowrap">
            Delivery From : {{ item.selected_date | date : "d MMMM yyyy hh:mm a" }}
          </p>
          <p class="text-red-600 font-bold text-xl">
            ₹{{ item.price }}
          </p>
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>
</div>
<!-- Order Summary Section -->
<div class="col-span-1">
  <div class="p-5 border rounded">
    <div class="flex items-center justify-between mb-4 border-b-2 border-custom-teal">
      <h2 class="font-medium text-2xl text-custom-gray font-times mb-4">Order Summary</h2>
      <button
      (click)="placeOrder()"
        class="mb-4 bg-custom-gray hover:bg-gray-900 text-custom-text-color-button font-normal lg:font-medium py-1 lg:py-2 px-2 lg:px-4 font-poppins text-sm lg:text-lg tracking-widest w-fit"
      >
        Place Order
      </button>
    </div>

    <!-- Summary Details -->
    <ng-container *ngIf="subscriptionData">
      <div class="font-poppins">
      <div class="flex justify-between items-center mb-3">
        <span class="text-gray-700  ">Items:</span>
        <span class="text-gray-900 font-medium  ">₹{{ subscriptionData.price }}</span>
      </div>
      <div class="flex justify-between items-center mb-3">
        <span class="text-gray-700  ">Delivery:</span>
        <span class="text-gray-900 font-medium  ">Free</span>
      </div>
      <div class="flex justify-between items-center mb-3">
        <span class="text-gray-700  ">Total:</span>
        <span class="text-gray-900 font-medium  ">₹{{ subscriptionData.price }}</span>
      </div>
    </div>
    </ng-container>

    <ng-container *ngIf="!subscriptionData && selectedCartItems.length">
      <div class="font-poppins">
      <div class="flex justify-between items-center mb-3">
        <span class="text-gray-700  ">Items:</span>
        <span class="text-gray-900 font-medium  ">₹{{ totalCartPrice }}</span>
      </div>
      <div class="flex justify-between items-center mb-3">
        <span class="text-gray-700  ">Delivery:</span>
        <span class="text-gray-900 font-medium  ">Free</span>
      </div>
      <div class="flex justify-between items-center mb-3">
        <span class="text-gray-700  ">Total:</span>
        <span class="text-gray-900 font-medium  ">₹{{ totalCartPrice }}</span>
      </div>
      </div>
    </ng-container>

    <hr class="my-4" />
    <div class="flex justify-between items-center text-xl font-extrabold text-red-600 font-poppins">
      <span>Order Total:</span>
      <span>₹{{ subscriptionData?.price || totalCartPrice }}</span>
    </div>
  </div>
</div>


      </div>
    </div>
  </body>
    <app-footer></app-footer>

</html>
