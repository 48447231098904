
<!-- header.html -->
<nav class="bg-white dark:bg-white w-full z-20 top-0 border-b border-gray-500">
  <div class="max-w-screen-xl min-w-[100%] mx-auto flex flex-col lg:flex-row items-center justify-between h-32 p-2 xl:p-14">
    

   <!-- Links, Logo, and Icons Container -->
<div class="w-full flex items-center justify-between">
  
 <!-- Logo - Always Visible -->
 <a href="/" class="logo-link flex justify-center items-center lg:justify-start w-full lg:w-1/3">
  <div class="w-full flex-shrink-0 flex justify-center items-center 2xl:justify-start h-[60px] md:h-[80px] lg:h-[57px]  md:w-[600px] lg:w-[650px] ml-[10px] lg:ml-[-80px]">
  <!-- Your logo goes here -->
  <img class="h-full w-full object-cover" src="../../assets/images/Nirdhara Website Icons-03.png" alt="Logo img" />
</div>
</a>
  <!-- Links and Icons - Visible on large screens -->
  <div class="hidden lg:flex lg:w-2/3 items-center justify-end space-x-3" *ngIf="showLinks">
    <ul class="flex space-x-6 xl:space-x-12 font-medium 3xl:pr-10 tracking-[2.5px] xl:tracking-[3.5px]">
      <li>
        <a routerLink="/juices" class="block py-4 text-xs xl:text-sm 2xl:text-lg 3xl:text-custom-font-[19px] 3xl:leading-custom-line-30 text-gray-900 hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-blue-700 font-poppins font-medium">
          JUICES
        </a>
      </li>
      <li>
        <a routerLink="/information" class="block py-4 text-xs xl:text-sm 2xl:text-lg 3xl:text-custom-font-[19px] 3xl:leading-custom-line-30 text-gray-900 hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-blue-700 font-poppins font-medium">
          INFORMATION
        </a>
      </li>
      <li>
        <a routerLink="/subscribe" class="block py-4 text-xs xl:text-sm 2xl:text-lg 3xl:text-custom-font-[19px] 3xl:leading-custom-line-30 text-gray-900 hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-blue-700 font-poppins font-medium">
          SUBSCRIBE
        </a>
      </li>
      <li>
        <a routerLink="/contact" class="block py-4 text-xs xl:text-sm 2xl:text-lg 3xl:text-custom-font-[19px] 3xl:leading-custom-line-30 text-gray-900 hover:bg-gray-100 lg:hover:bg-transparent lg:hover:text-blue-700 font-poppins font-medium">
          CONTACT
        </a>
      </li>
    </ul>
    <div class="flex items-center space-x-10">
      <a routerLink="/cart" class="relative">
        <fa-icon [icon]="faShoppingCart" class="text-gray-900 text-2xl"></fa-icon>
        <span  class="absolute bottom-6 left-5 bg-red-600 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">{{ cartCount }}</span>
      </a>
      <!-- Show Login Icon if User is Not Logged In -->
      <div *ngIf="!isLoggedIn" (click)="navigateToLogin()" role="button">
        <i class="fa-solid fa-right-to-bracket text-gray-900 text-2xl cursor-pointer"></i>
      </div>
      <div class="dropdown dropdown-end"  *ngIf="isLoggedIn">
        <!-- Icon Trigger -->
        <div tabindex="0" role="button">
          <fa-icon [icon]="faUser" class="text-gray-900  text-2xl"></fa-icon>
        </div>
        
        <!-- Dropdown Menu -->
        <ul
        tabindex="0"
          class="menu menu-sm dropdown-content bg-custom-teal rounded-box z-[1] mt-3 w-44 p-4 font-poppins font-medium shadow">
          <li>
            <a routerLink="/profile" class="text-lg dark:text-black hover:bg-transparent hover:text-blue-700 flex items-center">
              <i class="fas fa-user-circle text-gray-900 hover:text-blue-700 text-xl"></i>&nbsp;
              PROFILE
            </a>
          </li>
          <li>
            <a (click)="logout()" class="text-lg dark:text-black hover:bg-transparent hover:text-blue-700 flex items-center">
              <i class="fas fa-right-from-bracket text-gray-900 hover:text-blue-700 text-xl"></i>&nbsp;
              LOGOUT
            </a>
          </li>
        </ul>
      </div>
      
      
    </div>
  </div>
</div>


    <!-- Burger Menu and Icons - Visible on small screens -->
<div class="lg:hidden w-full flex justify-between items-center relative" *ngIf="showLinks">
  <button (click)="toggleMenu()">
    <fa-icon [icon]="menuOpen ? faTimes : faBars" class="text-gray-900  text-2xl"></fa-icon>
  </button>
  <div class="flex items-center space-x-4">
    <a routerLink="/cart" class="relative">
      <fa-icon [icon]="faShoppingCart" class="text-gray-900 text-2xl"></fa-icon>
      <span  class="absolute bottom-6 left-5 bg-red-600 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">{{ cartCount }}</span>
    </a>
    <!-- Show Login Icon if User is Not Logged In -->
    <div *ngIf="!isLoggedIn" (click)="navigateToLogin()" role="button">
      <i class="fa-solid fa-right-to-bracket text-gray-900 text-2xl cursor-pointer"></i>
    </div>
    <div class="dropdown dropdown-end" *ngIf="isLoggedIn">
      <!-- Icon Trigger -->
      <div tabindex="0" role="button">
        <fa-icon [icon]="faUser" class="text-gray-900  text-2xl"></fa-icon>
      </div>
      
      <!-- Dropdown Menu -->
      <ul
        tabindex="0"
          class="menu menu-sm dropdown-content bg-custom-teal rounded-box z-[1] mt-3 w-44 p-4 font-poppins font-medium shadow">
          <li>
            <a routerLink="/profile" class="text-lg dark:text-black hover:bg-transparent hover:text-blue-700 flex items-center">
              <i class="fas fa-user-circle text-gray-900 hover:text-blue-700 text-xl"></i>&nbsp;
              PROFILE
            </a>
          </li>
          <li>
            <a (click)="logout()" class="text-lg dark:text-black hover:bg-transparent hover:text-blue-700 flex items-center">
              <i class="fas fa-right-from-bracket text-gray-900 hover:text-blue-700 text-xl"></i>&nbsp;
              LOGOUT
            </a>
          </li>
        </ul>
    </div>
  </div>
  <!-- Links - Visible on small screens -->
  <div class="absolute mt-28 left-10 bg-custom-teal rounded-lg p-4" [ngClass]="menuOpen ? 'block' : 'hidden'">
    <ul class="flex flex-col items-start justify-start font-medium">
      <li>
        <a routerLink="/juices" class="block py-2 px-4 text-lg text-gray-900 hover:bg-transparent hover:hover:text-blue-700 font-poppins font-medium">
          JUICES
        </a>
      </li>
      <li>
        <a routerLink="/information" class="block py-2 px-4 text-lg text-gray-900 hover:bg-transparent hover:hover:text-blue-700 font-poppins font-medium">
          INFORMATION
        </a>
      </li>
      <li>
        <a routerLink="/subscribe" class="block py-2 px-4 text-lg text-gray-900 hover:bg-transparent hover:hover:text-blue-700 font-poppins font-medium">
          SUBSCRIBE
        </a>
      </li>
      <li>
        <a routerLink="/contact" class="block py-2 px-4 text-lg text-gray-900 hover:bg-transparent hover:hover:text-blue-700 font-poppins font-medium">
          CONTACT
        </a>
      </li>
    </ul>
  </div>
</div>

  </div>
</nav>
