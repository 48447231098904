<body class="dark:bg-white">
  <div class="overflow-hidden">  
      <div class="flex flex-col min-h-screen bg-white-100">
        <app-header></app-header>
        <div class="flex-grow flex justify-center items-center">
          <div class="relative py-3 sm:mx-auto sm:max-w-xl">
            <div class="absolute inset-0 -skew-y-6 transform bg-[#CCD56A] shadow-lg sm:-rotate-6 sm:skew-y-0 sm:rounded-3xl"></div>
            <div class="relative bg-white px-4 py-6 shadow-lg sm:rounded-3xl sm:p-20">
              <div class="mx-auto max-w-md">
                <div>
                  <h1 class="text-4xl font-times dark:text-black">Login</h1>
                </div>
                <form (ngSubmit)="onSubmit()" class="divide-y divide-gray-200">
                  <div class="space-y-4 py-3 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7">
                    <div class="relative">
                      <input
                        type="text"
                        [(ngModel)]="email"
                        name="email"
                        class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-times"
                        placeholder="Email address"
                        id="email"
                        required
                      />
                      <label
                        for="email"
                        class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                      >
                        Email Address
                      </label>
                    </div>
                    <div class="relative">
                      <input
                        type="password"
                        [(ngModel)]="password"
                        name="password"
                        class="peer focus:border-[#CCD56A] h-10 w-full border-b-2 border-gray-300 text-gray-900 dark:bg-white placeholder-transparent focus:outline-none font-times"
                        placeholder="Password"
                        id="password"
                        required
                      />
                      <label
                        for="password"
                        class="absolute left-0 -top-3.5 text-sm text-gray-600 font-times transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
                      >
                        Password
                      </label>
                    </div>
                    <div class="flex justify-center mt-2">
                      <button
                        type="submit"
                        class="bg-custom-gray hover:bg-gray-900 text-custom-text-color-button font-normal lg:font-medium py-1 lg:py-2 px-2 lg:px-4 font-times text-sm lg:text-lg tracking-widest w-fit"
                      >
                        <i class="fas fa-check mr-2"></i> Submit
                      </button>
                    </div>
                  </div>
                </form>
                <div class="flex justify-center mt-2">
                  <p class="text-lg font-times dark:text-custom-gray">
                    Don’t have an account? 
                    <a routerLink="/register" class="text-blue-600 hover:underline">
                      Register here
                    </a>
                  </p>
                </div>
                
                <div class="flex w-full justify-center mt-4">
                  <button class="border-2 border-[#1D1A1A] text-custom-gray py-2 px-4 font-times text-sm">
                      <a routerLink="/forgot-password">
                    <span class="flex items-center">Forgot Password&nbsp;&nbsp;
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                      </svg>
                    </span>
                  </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>