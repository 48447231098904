import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrl: './email-verification.component.css'
})
export class EmailVerificationComponent {

  otp: string[] = ['', '', '', '', '', '']; // Updated to 6 elements
  email: string = ''; // Add a variable to store the email
  resendOtpCountdown: number = 120; // Initialize the countdown to 120

  constructor(private router: Router, private apiService: ApiService,private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    // Retrieve the email from the router state
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as { email: string };
    if (state) {
      this.email = state.email;
    }
    // Start the countdown when the component initializes
    this.startCountdown();
  }

  goBack(): void {
    
      this.router.navigate(['/login']);
    
  }


  onKeyUp(event: KeyboardEvent, index: number): void {
    const input = event.target as HTMLInputElement;
    const key = event.key;

    if (parseInt(input.value) >= 0 && parseInt(input.value) <= 9) {
      const nextInput = document.getElementById(`input${index + 2}`);
      if (nextInput) {
        nextInput.focus();
      }
    } else {
      input.value = '';
    }

    if (key === 'Backspace' || key === 'Delete') {
      const prevInput = document.getElementById(`input${index}`);
      if (prevInput && index > 0) {
        prevInput.focus();
      }
    }
  }

  handleVerifyOtp(): void {
    const code = this.otp.join('');
    this.apiService.verifyEmail(this.email, code).subscribe({
      next: () => {
        this.openSnackBar('User registration successful!', 'custom-snackbar');
        this.router.navigate(['/login']);
      },
      error: (error) => {
        const errorMessage = error.error.detail || 'Error in verification!';
        // console.error('Error in verification:', errorMessage);
        this.openSnackBar(errorMessage, 'custom-snackbar');
      },
    });
  }

  handleSendOtp(): void {
    if (this.resendOtpCountdown === 0) {
      this.apiService.sendOtp(this.email).subscribe({
        next: () => {
          this.startCountdown();
          this.openSnackBar('OTP has been resent!', 'custom-snackbar');
        },
        error: (error) => {
          const errorMessage = error.error.detail || 'Failed to send otp!';
          // console.error('Failed to send otp:', errorMessage);
          this.openSnackBar(errorMessage, 'custom-snackbar');
        },
      });
    }
  }

  startCountdown(): void {
    this.resendOtpCountdown = 120; // Reset countdown to 120 seconds
    const interval = setInterval(() => {
      this.resendOtpCountdown--;
      if (this.resendOtpCountdown <= 0) {
        clearInterval(interval);
      }
    }, 1000);
  }

  
  openSnackBar(message: string, panelClass: string) {
    const config = new MatSnackBarConfig();
    config.duration = 2000;
    config.panelClass = [panelClass];
    this.snackBar.open(message, 'Close', config);
  }
}
