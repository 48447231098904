import { Component } from '@angular/core';

@Component({
  selector: 'app-our-bestsellers',
  templateUrl: './our-bestsellers.component.html',
  styleUrl: './our-bestsellers.component.css'
})
export class OurBestsellersComponent {

}
