import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Product } from '../api.service'; // Import the Product interface
import { MatSnackBar } from '@angular/material/snack-bar'; // Import MatSnackBar

@Component({
  selector: 'app-product-popup',
  templateUrl: './product-popup.component.html',
  styleUrls: ['./product-popup.component.css']
})
export class ProductPopupComponent {
  @Input() product: Product = {
    product_master_id: '',
    product_name: '',
    quantity: '',
    price: null // Initialize price as null
  };
  
  @Input() editMode: boolean = false;

  @Output() productUpdated = new EventEmitter<Product>();
  @Output() closePopupEvent = new EventEmitter<void>();

  productForm: FormGroup;

  constructor(private snackBar: MatSnackBar) {
    this.productForm = new FormGroup({
      product_name: new FormControl(this.product.product_name, [Validators.required]),
      quantity: new FormControl(this.product.quantity, [
        Validators.required,
        Validators.pattern(/^\d*\.?\d+$/) // Pattern for numeric values including float
      ]),
      price: new FormControl(this.product.price, [
        Validators.required,
        Validators.pattern(/^\d*\.?\d+$/) // Pattern for numeric values including float
      ]),
    });

    this.productForm.get('product_name')?.valueChanges.subscribe(value => {
      if (value) {
        this.productForm.patchValue({
          product_name: this.toTitleCase(value)
        }, { emitEvent: false });
      }
    });
  }

  ngOnChanges() {
    if (this.editMode && this.product) {
      this.productForm.patchValue(this.product);
    }
  }  

  closePopup() {
    this.closePopupEvent.emit();
  }

  onSubmit() {
    if (this.productForm.valid) {
      const updatedProduct: Product = { ...this.product, ...this.productForm.value };
      if (this.editMode) {
        this.productUpdated.emit(updatedProduct);
      } else {
        this.productUpdated.emit(updatedProduct);
      }
      this.closePopup();
    } else {
      this.showValidationErrors();
    }
  }

  showValidationErrors() {
    const quantityControl = this.productForm.get('quantity');
    const priceControl = this.productForm.get('price');

    if (quantityControl?.invalid) {
      this.snackBar.open('Quantity must be a valid number.', 'Close', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }

    if (priceControl?.invalid) {
      this.snackBar.open('Price must be a valid number.', 'Close', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    }
  }

  toTitleCase(str: string): string {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
    });
  }
}
