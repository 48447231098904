import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { CommonModule , DatePipe } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AboveHeaderComponent } from './above-header/above-header.component';
import { FooterComponent } from "./footer/footer.component";
import { SubscribeComponent } from './subscribe/subscribe.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { SubscriptionBenefitsComponent } from "./subscription-benefits/subscription-benefits.component";
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // Import FormsModule here
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { CartComponent } from './cart/cart.component';
import { ProductComponent } from './product/product.component';
import { ProductPopupComponent } from './product-popup/product-popup.component';
import { CustomerComponent } from './customer/customer.component';
import { AdminSubscriptionComponent } from './admin-subscription/admin-subscription.component';
import { AddressDetailsPopupComponent } from './address-details-popup/address-details-popup.component';
import { LoginPopupComponent } from './login-popup/login-popup.component';
import { RegisterPopupComponent } from './register-popup/register-popup.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ReturnPolicyComponent } from './return-policy/return-policy.component';
import { ShippingPolicyComponent } from './shipping-policy/shipping-policy.component';
import { JuiceDeliveryComponent } from './juice-delivery/juice-delivery.component';
import { AboveHeaderNoTextComponent } from './above-header-no-text/above-header-no-text.component';
import { FooterBgGreenComponent } from './footer-bg-green/footer-bg-green.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { OurBestsellersComponent } from './our-bestsellers/our-bestsellers.component';
import { JuiceOptionsComponent } from './juice-options/juice-options.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,HeaderComponent,SidebarComponent,AboveHeaderComponent,AboveHeaderNoTextComponent,
    SubscribeComponent,SubscriptionComponent,LoginComponent,RegisterComponent,ForgotPasswordComponent,
    EmailVerificationComponent,FooterComponent,AdminDashboardComponent,CustomerComponent,AdminSubscriptionComponent,
    SubscriptionBenefitsComponent,CheckoutComponent,CartComponent,ProductComponent,ProductPopupComponent,AddressDetailsPopupComponent,
    LoginPopupComponent,RegisterPopupComponent,TermsConditionsComponent,RefundPolicyComponent,PrivacyPolicyComponent,ReturnPolicyComponent,ShippingPolicyComponent,
    JuiceDeliveryComponent,FooterBgGreenComponent,AboutUsComponent,OurBestsellersComponent,JuiceOptionsComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule, ReactiveFormsModule,FormsModule,
    FontAwesomeModule,
    AppRoutingModule,RouterModule,BrowserAnimationsModule, // Required for animations
    MatSnackBarModule,
   
],
providers: [
  provideHttpClient(withInterceptorsFromDi()),
  provideAnimationsAsync(),
  DatePipe
],
  bootstrap: [AppComponent]
})
export class AppModule { }
